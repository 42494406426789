import { weekDayNames } from "../../common/constants";
import { useState, useEffect, useContext } from "react";
import MonthStore, { DateInterface } from "./store";
import { observer } from "mobx-react-lite";
import { MyCalendarContextType, MyCalendarContext } from "../../context";
import { EventInterface } from "../../store";
import { Popover, PopoverContent, PopoverTrigger } from "../../../FloatingPopover";
import CakeIcon from "@mui/icons-material/Cake";
import CelebrationIcon from "@mui/icons-material/Celebration";
import "./styles.css";
import Date from "./components/Date";

interface MonthInterface {
    year: number;
    month: number;
    showMonth?: boolean;
    events: Array<EventInterface>;
}

interface DatePopoverWrapperInterface {
    date: DateInterface;
    monthStore: MonthStore;
}

interface EventPopoverItemInterface {
    event: EventInterface;
}

const EventPopoverItem = observer(({ event }: EventPopoverItemInterface) => {
    if (event.type === "birthdate") {
        return (
            <div>
                <CakeIcon fontSize="small" />
                <span>{event.description}</span>
            </div>
        );
    } else if (event.extra_type === "Праздник" || event.extra_type === "Предпраздничный") {
        return (
            <div>
                <CelebrationIcon fontSize="small" />
                <span>{event.description}</span>
            </div>
        );
    }
    return null;
});

const DatePopoverWrapper = observer(({ date, monthStore }: DatePopoverWrapperInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [open, setOpen] = useState(false);

    const eventsFilter = (event: EventInterface) => {
        return !(event.extra_type === "Воскресенье" || event.extra_type === "Суббота" || event.hidden);
    };

    return (
        <td
            className={`rounded relative text-center ${date.date.isToday() ? "bg-blue-200 dark:dark-primary-500" : ""} ${
                open
                    ? "outline outline-offset-[-1px] outline-[1px] outline-blue-200 dark:outline-[#A688FA]"
                    : "outline-none"
            }`}
        >
            {myCalendarStore.showTodayEvents ? (
                <Popover open={open} onOpenChange={setOpen} placement="top-start">
                    <PopoverTrigger asChild onClick={() => setOpen((v) => !v)}>
                        <div>
                            <Date date={date} monthStore={monthStore} />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="bg-gray-200 dark:dark-surface-400 rounded-xl p-2 text-xs">
                        {date.events.filter(eventsFilter).length > 0 ? (
                            <div className="event-popover">
                                {date.events.filter(eventsFilter).map((ev, idx) => (
                                    <EventPopoverItem key={idx} event={ev} />
                                ))}
                            </div>
                        ) : (
                            <div>Событий нет</div>
                        )}
                    </PopoverContent>
                </Popover>
            ) : (
                <Date date={date} monthStore={monthStore} />
            )}
        </td>
    );
});

const Month = ({ year, month, showMonth = true, events }: MonthInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [monthStore, setMonthStore] = useState(() => new MonthStore(year, month, events));

    useEffect(() => {
        setMonthStore(new MonthStore(year, month, events));
    }, [myCalendarStore.date, myCalendarStore.events, myCalendarStore.vacationList]);

    return (
        <div className="bg-white dark:dark-surface-300 rounded-3xl p-2">
            {showMonth && (
                <div className="capitalize font-semibold text-xl text-center mb-2">{monthStore.monthFirstDate.format("MMMM")}<hr className="mt-2 border"/></div>
            )}
            <table className="w-full border-separate border-spacing-1">
                <thead>
                    <tr className="opacity-30">
                        {weekDayNames.map((name) => (
                            <th className="text-center pb-1" key={name}>
                                {name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {monthStore.monthData.map((week, weekIdx) => (
                        <tr key={weekIdx}>
                            {week.map((date, dateIdx) =>
                                date?.date ? (
                                    <DatePopoverWrapper date={date} monthStore={monthStore} key={dateIdx} />
                                ) : (
                                    <td key={dateIdx} />
                                )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default observer(Month);
