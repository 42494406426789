import { observer } from "mobx-react-lite";
import { useState, useContext } from "react";
import { Dialog, DialogContent, Switch } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import "./styles.css";
import EventsFilterClass from "./store";

const EventsFilter = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [store] = useState<EventsFilterClass>(() => new EventsFilterClass(myCalendarStore));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div className="my-tool-icon" title="Фильтр" onClick={handleClickOpen}>
                <FilterListIcon />
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <div id="calendarFilters" className="flex-col gap-3">
                        {myCalendarStore.fetchList.includes("birthdate") && (
                            <div>
                                Дни рождения{" "}
                                <Switch
                                    checked={store.birthdateFilterChecked}
                                    onChange={store.handleBirthdateFilterChange}
                                />
                            </div>
                        )}
                        {myCalendarStore.fetchList.includes("holidays") && (
                            <div>
                                Предпраздничные дни{" "}
                                <Switch
                                    checked={store.preHolidayFilterChecked}
                                    onChange={store.handlePreHolidayFilterChange}
                                />
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default observer(EventsFilter);
