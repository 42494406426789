import { createContext, ReactNode } from "react";
import DomainStore from "../stores/domainStore";
import UIStore from "../stores/uiStore";

export type AuthContextType = {
    domainStore: DomainStore;
    uiStore: UIStore;
};

interface AuthProviderInterface {
    children: ReactNode;
}

const domainStore = new DomainStore();
const uiStore = new UIStore();
const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: AuthProviderInterface) => {
    const contextData = {
        domainStore: domainStore,
        uiStore: uiStore,
    };
    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

export default AuthContext;
