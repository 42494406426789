import { useContext } from "react";
import { observer } from "mobx-react-lite";
import AuthContext from "../../../context/AuthContext";
import PersonIcon from "@mui/icons-material/Person";
import { Skeleton } from "@mui/material";
import { AuthContextType } from "../../../context/AuthContext";

const ProfilePhoto = () => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const size = 42;

    if (!domainStore.thumbnailLoading && !domainStore.thumbnailError && domainStore.user?.profile?.thumbnail) {
        return (
            <img
                src={domainStore.user.profile.thumbnail}
                style={{ width: size, height: size }}
                className="rounded-full border-solid border dark:border-gray-500"
            />
        );
    } else if (!domainStore.thumbnailError && domainStore.thumbnailLoading) {
        return <Skeleton variant="circular" width={size} height={size} />;
    }
    return (
        <div
            style={{ height: size, width: size }}
            className="bg-gray-300 rounded-full flex items-center justify-center"
        >
            <PersonIcon className="text-white" />
        </div>
    );
};

export default observer(ProfilePhoto);
