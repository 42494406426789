import { BaseSyntheticEvent, MouseEvent, useContext, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion } from "framer-motion";
import "./styles.css";
import { observer } from "mobx-react-lite";
import { ManagerViewContext, ManagerViewContextType } from "../../../../context";
import { Popover, PopoverTrigger, PopoverContent } from "../../../../../FloatingPopover";
import { monthList } from "../../../../common/constants";
import dayjs from "dayjs";

const TapAnimation = {
    scale: 0.8,
};

const MonthSelector = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { setLocalDate } = useContext(ManagerViewContext) as ManagerViewContextType;
    const [monthOpen, setMonthOpen] = useState<boolean>(false);
    const [yearOpen, setYearOpen] = useState<boolean>(false);
    const [yearList] = useState<Array<number>>(() => {
        let arr: Array<number> = [];
        const yearsCount = 30;
        const nextYear = dayjs().add(1, "year").year();
        for (let i: number = nextYear; i >= nextYear - yearsCount; i--) {
            arr.push(i);
        }
        return arr;
    });

    const handleMonthChange = (direction: number) => {
        let newDate;
        if (direction < 0) {
            newDate = myCalendarStore.date.subtract(1, "month");
        } else {
            newDate = myCalendarStore.date.add(1, "month");
        }
        if (newDate.year() !== myCalendarStore.date.year()) {
            myCalendarStore.setCalendarRendered(false);
        }
        setLocalDate({
            date: newDate,
            behavior: newDate.year() === myCalendarStore.date.year() ? "smooth" : "instant",
        });
        myCalendarStore.setDate(newDate);
    };

    const handleMonthClick = (e: BaseSyntheticEvent) => {
        if (e.target) {
            const newDate = dayjs({
                year: myCalendarStore.date.year(),
                month: e.target.dataset.num,
                date: 1,
            });
            setLocalDate({ date: newDate, behavior: "smooth" });
            myCalendarStore.setDate(newDate);
        }
    };

    const handleYearClick = (e: BaseSyntheticEvent) => {
        if (e.target) {
            const newDate = dayjs({
                year: e.target.dataset.num,
                month: 0,
                date: 1,
            });
            setLocalDate({ date: newDate, behavior: "instant" });
            myCalendarStore.setDate(newDate);
        }
    };

    return (
        <div id="managerMonthSelector" className="flex items-center gap-3 min-w-48 justify-between">
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleMonthChange(-1)}>
                <ArrowBackIosNewIcon className="arrow-icon" />
            </motion.button>
            <div className="font-semibold capitalize">
                <Popover open={monthOpen} onOpenChange={setMonthOpen} placement="bottom">
                    <PopoverTrigger asChild onClick={() => setMonthOpen((v) => !v)}>
                        <button className="capitalize">{myCalendarStore.date.format("MMMM")}</button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <div className="selector-popover grid grid-cols-3 gap-2 ">
                            {monthList.map((el, idx) => (
                                <button key={idx} className="btn" onClick={handleMonthClick} data-num={idx}>
                                    {el}
                                </button>
                            ))}
                        </div>
                    </PopoverContent>
                </Popover>
                {", "}
                <Popover open={yearOpen} onOpenChange={setYearOpen} placement="bottom" returnFocus={false}>
                    <PopoverTrigger asChild onClick={() => setYearOpen((v) => !v)}>
                        <button className="capitalize">{myCalendarStore.date.year()}</button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <div className="selector-popover max-h-36 overflow-y-auto flex flex-col gap-2">
                            {yearList.map((el, idx) => (
                                <button key={idx} onClick={handleYearClick} data-num={el}>
                                    {el}
                                </button>
                            ))}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleMonthChange(1)}>
                <ArrowForwardIosIcon className="arrow-icon" />
            </motion.button>
        </div>
    );
};

export default observer(MonthSelector);
