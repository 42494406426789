import { observer } from "mobx-react-lite";
import { DateInterface } from "../../store";
import { useContext, useEffect, useState, useRef } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { enqueueSnackbar } from "notistack";
import MonthStore from "../../store";
import "./styles.css";
import dayjs from "dayjs";

interface DateComponentInterface {
    date: DateInterface;
    monthStore: MonthStore;
}

const Date = ({ date, monthStore }: DateComponentInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [firstVacationDay, setFirstVacationDay] = useState<boolean>(false);
    const [lastVacationDay, setLastVacationDay] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    // Изменение ячеек при изменении диапазона дат
    useEffect(() => {
        let flag = false;
        for (const vacation of myCalendarStore.vacationList.filter((el) => !el.hidden)) {
            if (date.date.isSame(vacation.dateStart) && date.date.isSame(vacation.dateEnd)) {
                setFirstVacationDay(true);
                setLastVacationDay(true);
                flag = true;
            } else if (date.date.isSame(vacation.dateStart)) {
                setFirstVacationDay(true);
                flag = true;
            } else if (date.date.isBetween(vacation.dateStart, vacation.dateEnd)) {
                setActive(true);
                flag = true;
            } else if (date.date.isSame(vacation.dateEnd)) {
                setLastVacationDay(true);
                flag = true;
            }
        }
        if (!flag) {
            setActive(false);
            setFirstVacationDay(false);
            setLastVacationDay(false);
        }

        // Пояснение к зависимостям
        // Даты должны обновляться как из-за изменения списка отпусков и изменения текущего года (даты),
        //  так и из-за изменения компонента месяца целиком
    }, [myCalendarStore.vacationList, myCalendarStore.date, monthStore]);

    // Перенестись к конкретной дате
    useEffect(() => {
        if (myCalendarStore.scrollDate && date.date.isSame(myCalendarStore.scrollDate) && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            myCalendarStore.setScrollDate(null);
        }
    }, [myCalendarStore.scrollDate, monthStore]);

    const handleSelectDate = () => {
        if (myCalendarStore.vacationPlanAndCreate) {
            if (myCalendarStore.vacationMode === "planMode" && myCalendarStore.remainingPlanDays > 0) {
                // Нельзя снова выбрать существующий период
                for (const vacation of myCalendarStore.vacationList) {
                    if (date.date.isBetween(vacation.dateStart, vacation.dateEnd, null, "[]")) return;
                }

                // Выбрали первую дату
                if (!myCalendarStore.selectedDayStart) {
                    myCalendarStore.setSelectedDayStart(date.date);
                } else if (myCalendarStore.selectedDayStart) {
                    // Выбираем вторую дату

                    if (date.date.isBefore(myCalendarStore.selectedDayStart)) {
                        myCalendarStore.setSelectedDayStart(null);
                        myCalendarStore.setSelectedDayEnd(null);
                    } else {
                        // Посчитаем, сколько дней займёт отпуск с учётом праздничных дней
                        let usedDays = date.date.diff(myCalendarStore.selectedDayStart, "d") + 1;
                        for (const holidayDay of myCalendarStore.events) {
                            if (
                                holidayDay.extra_type === "Праздник" &&
                                dayjs({
                                    year: holidayDay.datetime.y,
                                    month: holidayDay.datetime.M - 1,
                                    date: holidayDay.datetime.d,
                                }).isBetween(myCalendarStore.selectedDayStart, date.date, null, "[]")
                            ) {
                                usedDays -= 1;
                            }
                        }

                        const newRemain = myCalendarStore.remainingPlanDays - usedDays;

                        // Если дней не хватает
                        if (newRemain < 0) {
                            enqueueSnackbar("Недостаточно дней");
                            return;
                        }

                        // Проверим, не будет ли отпуска в отпуске
                        for (const vacation of myCalendarStore.vacationList) {
                            if (
                                vacation.dateStart.isBetween(myCalendarStore.selectedDayStart, date.date, null, "[]") ||
                                vacation.dateEnd.isBetween(myCalendarStore.selectedDayStart, date.date, null, "[]")
                            )
                                return;
                        }

                        myCalendarStore.setVacationList([
                            {
                                dateStart: myCalendarStore.selectedDayStart,
                                dateEnd: date.date,
                                type: "planVacation",
                                hidden: false,
                                usedDays,
                                status: "draft",
                            },
                            ...myCalendarStore.vacationList,
                        ]);
                        myCalendarStore.setRemainingPlanDays(newRemain);

                        myCalendarStore.setSelectedDayStart(null);
                        myCalendarStore.setSelectedDayEnd(null);
                    }
                }
            }
        }
    };

    return (
        <div
            ref={ref}
            className={`date-block size-full relative rounded
                ${
                    !date.date.isToday() && !myCalendarStore.vacationPlanAndCreate
                        ? "dark:hover:dark-surface-400 hover:bg-blue-50"
                        : ""
                } 
                ${
                    date.date.isSame(myCalendarStore.selectedDayStart) ||
                    date.date.isSame(myCalendarStore.selectedDayEnd) ||
                    date.date.isBetween(myCalendarStore.selectedDayStart, myCalendarStore.selectedDayEnd)
                        ? "date-in-range"
                        : ""
                }  
                ${
                    date.events.filter(
                        (el) =>
                            el.extra_type === "Праздник" ||
                            el.extra_type === "Воскресенье" ||
                            el.extra_type === "Суббота"
                    ).length > 0
                        ? "bg-red-400 dark:bg-[#8f8f96]"
                        : ""
                }                
                ${
                    date.events.filter((el) => el.extra_type === "Предпраздничный" && !el.hidden).length > 0
                        ? "bg-yellow-300 dark:bg-slate-400"
                        : ""
                }
                ${
                    myCalendarStore.view === "month"
                        ? "px-3 py-5 sm:py-7 md:py-9 lg:py-11 xl:py-14"
                        : "p-2.5 2xl:px-1 xl:py-3"
                }`}
            onClick={handleSelectDate}
        >
            {(firstVacationDay || lastVacationDay) && (
                <div className={`absolute inset-0 flex justify-center items-center`}>
                    <div
                        className={`date-in-range absolute top-0 bottom-0 ${
                            firstVacationDay && !lastVacationDay ? "rounded-l-full left-0 -right-1" : ""
                        } ${lastVacationDay && !firstVacationDay ? "rounded-r-full right-0 -left-1" : ""} ${
                            firstVacationDay === lastVacationDay ? "rounded-full right-0 left-0" : ""
                        }`}
                    />
                    <div className="z-0 size-8 xl:size-7 rounded-full bg-[#C39A6A] dark:bg-orange-200" />
                </div>
            )}
            {active && (
                <div
                    className={`absolute top-0 bottom-0 date-in-range ${date.date.day() === 1 ? "left-0" : "-left-1"} ${
                        date.date.day() === 0 ? "right-0" : "-right-1"
                    }`}
                />
            )}

            <div
                className={`date-number relative`}
            >
                {date.date.format("D")}
            </div>
            {date.events.length > 0 && (
                <div
                    className={`absolute bottom-1 inset-x-0 flex justify-center gap-1 ${
                        myCalendarStore.view === "month" ? "xl:bottom-3" : ""
                    }`}
                >
                    {date.events
                        .filter((i) => !i.extra_type && !i.hidden)
                        .slice(0, 3)
                        .map((ev, idx) => (
                            <div
                                key={idx}
                                className={`size-1 rounded-full bg-orange-300 ${
                                    myCalendarStore.view === "month" ? "sm:size-2" : ""
                                }`}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default observer(Date);
