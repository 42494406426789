import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import { motion } from "framer-motion";
import "./styles.css";

const TapAnimation = {
    scale: 0.8,
};

export const YearSelector = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    
    const handleYearChange = (direction: number) => {
        if (direction < 0) {
            myCalendarStore.setDate(myCalendarStore.date.subtract(1, "year"));
        } else {
            myCalendarStore.setDate(myCalendarStore.date.add(1, "year"));
        }
    };

    return (
        <div id="yearSelector" className="selector">
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleYearChange(-1)}>
                <ArrowBackIosNewIcon />
            </motion.button>
            <div>{myCalendarStore.date.format("YYYY")}</div>
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleYearChange(1)}>
                <ArrowForwardIosIcon />
            </motion.button>
        </div>
    );
});

export const MonthSelector = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const handleMonthChange = (direction: number) => {
        if (direction < 0) {
            myCalendarStore.setDate(myCalendarStore.date.subtract(1, "month"));
        } else {
            myCalendarStore.setDate(myCalendarStore.date.add(1, "month"));
        }
    };

    return (
        <div id="monthSelector" className="selector">
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleMonthChange(-1)}>
                <ArrowBackIosNewIcon />
            </motion.button>
            <div className="capitalize">{myCalendarStore.date.format("MMMM, YYYY")}</div>
            <motion.button whileTap={TapAnimation} className="selector-arrow" onClick={() => handleMonthChange(1)}>
                <ArrowForwardIosIcon />
            </motion.button>
        </div>
    );
});
