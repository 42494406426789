import { BaseSyntheticEvent, MouseEvent, useState, useContext } from "react";
import ProfilePhoto from "../ProfilePhoto";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import { AuthContextType } from "../../../../context/AuthContext";

const ProfileMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { domainStore, uiStore } = useContext(AuthContext) as AuthContextType;

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (e: BaseSyntheticEvent) => {
        if (e.target.id === "profileMenuItem") {
            navigate("/users/me");
        } else {
            domainStore.logout();
            uiStore.reset();
            navigate("/login");
        }
    };

    return (
        <>
            <div className="cursor-pointer" onClick={handleClick} title="Профиль">
                <ProfilePhoto />
            </div>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                autoFocus={false}
                slotProps={{
                    paper: {
                        sx: {
                            mt: 1,
                        },
                    },
                }}
            >
                <MenuItem id="profileMenuItem" onClick={handleMenuItemClick}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    Профиль
                </MenuItem>
                <Divider />
                <MenuItem id="logoutMenuItem" onClick={handleMenuItemClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Выход
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileMenu;
