import TipTapEditor from "../components/inputs/TipTapEditor";
import { useState } from "react";
import { Editor, useEditor } from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import Bold from "@tiptap/extension-bold";
import Strike from "@tiptap/extension-strike";
import Italic from "@tiptap/extension-italic";
import History from "@tiptap/extension-history";
import HardBreak from "@tiptap/extension-hard-break";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Image from "@tiptap/extension-image";
import Text from "@tiptap/extension-text";

interface HookInterface {
    text: string;
    setText: Function;
    disabled?: boolean;
    placeholder?: string;
}

export default function useMyTipTapEditor({
    text,
    setText,
    disabled = false,
    placeholder = "Введите текст",
}: HookInterface) {
    const handleTextChange = (editor: Editor) => {
        setText(editor.getHTML());
    };

    const [editor, setEditor] = useState<any>(
        useEditor({
            extensions: [
                Placeholder.configure({
                    placeholder: placeholder,
                }),
                Document,
                Paragraph,
                Text,
                Bold,
                Underline,
                Strike,
                Italic,
                History,
                Image.configure({
                    inline: true,
                    HTMLAttributes: {
                        class: "external-emoji",
                    },
                }).extend({
                    renderText({ node }) {
                        return node.attrs.alt ? node.attrs.alt : "";
                    },
                    renderHTML({ HTMLAttributes }) {
                        return ["span", { class: "external-emoji" }, HTMLAttributes.alt ? HTMLAttributes.alt : ""];
                    },
                }),
                HardBreak.extend({
                    addKeyboardShortcuts() {
                        return {
                            Enter: () => {
                                return this.editor.commands.setHardBreak();
                            },
                        };
                    },
                }),
                Link.configure({
                    openOnClick: false,
                    autolink: true,
                    defaultProtocol: "https",
                    protocols: ["ftp", "mailto", "tel"],
                    HTMLAttributes: {
                        class: "tip-tap-link",
                    },
                }),
            ],
            content: text,
            editable: !disabled,
            onUpdate: ({ editor }) => handleTextChange(editor as Editor),
        })
    );
    return [editor, <TipTapEditor text={text} setText={setText} disabled={disabled} editor={editor} />];
}
