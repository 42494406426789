import BaseProfile from "../components/BaseProfile";
import { useState, useEffect, useContext } from "react";
import { UserProfileStore } from "../stores";
import { ProfileContext } from "../context";
import AuthContext from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import { AuthContextType } from "../../../context/AuthContext";

interface UserProfileInterface {
    userId?: number;
}

const UserProfile = ({ userId }: UserProfileInterface) => {
    let id: number;
    const params = useParams();
    if (userId) {
        id = userId;
    } else {        
        id = params.id as any;
    }
    // const { id } = useParams();

    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [store] = useState(() => new UserProfileStore(domainStore));

    useEffect(() => {
        const fetchProfile = async () => {
            await store.fetchProfile(Number(id));
        };
        fetchProfile();
    }, []);
    return (
        <ProfileContext.Provider value={{ store: store }}>
            <BaseProfile></BaseProfile>
        </ProfileContext.Provider>
    );
};

export default UserProfile;
