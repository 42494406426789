import Month from "./components/Month";
import { useState, useContext, useEffect } from "react";
import MyCalendarStore, { ViewType, FetchType } from "./store";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { YearSelector, MonthSelector } from "./components/YearSelector";
import Tools from "./components/Tools";
import { MyCalendarContext, MyCalendarContextType, LocalDateInterface, ManagerViewContext } from "./context";
import VacationPlanBlock from "./components/VacationPlanBlock";
import "./styles.css";
import ManagerView from "./components/ManagerView";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import SubordinatesList from "./components/SubordinatesList";

interface MyCalendarInterface {
    year?: number;
    month?: number;
    view?: ViewType; // Дефолтный вид
    viewList?: Array<ViewType>; // Какие доступны виды календаря
    showToday?: boolean; // Есть ли кнопка перехода на текущую дату
    fetchList?: Array<FetchType>; // Какие данные подгрузить с сервера
    showTodayEvents?: boolean; // Показывать попап с событиями сегодня
    vacationPlanAndCreate?: boolean; // Режим планирования и создания отпуска
    eventsFilter?: boolean; // Фильтр отображаемых событий
    calendarStoreSetter?: Function; // Функция для внешнего доступа к стору календаря
}

const CalendarView = observer(() => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;

    useEffect(() => {
        // По у молчанию всегда достаём все события
        if (myCalendarStore.fetchList.length > 0) {
            const fetchEvents = async () => {
                await myCalendarStore.fetchEvents();
            };
            fetchEvents();
        }

        // Если включен режим планирования и создания отпуска
        if (myCalendarStore.vacationPlanAndCreate) {
            // Заберём все отпуска за текущий год
            const fetchHolidays = async () => {
                await myCalendarStore.fetchHolidays();
            };
            fetchHolidays();
        }
    }, [myCalendarStore.date, myCalendarStore.view]);

    useEffect(() => {
        myCalendarStore.setSelectedDayStart(null);
        myCalendarStore.setSelectedDayEnd(null);
    }, [myCalendarStore.date]);

    if (myCalendarStore.view === "year") {
        return (
            <div
                className={`${
                    myCalendarStore.vacationPlanAndCreate
                        ? "2xl:bg-[#E3EBF8] 2xl:dark:dark-surface-500 2xl:rounded-3xl 2xl:px-8 2xl:py-5"
                        : ""
                }`}
            >
                <div
                    className={`mb-5 flex justify-center w-full top-5 ${
                        !myCalendarStore.vacationPlanAndCreate ? "sticky z-20" : ""
                    }`}
                >
                    <YearSelector />
                </div>
                <div
                    className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 ${
                        myCalendarStore.vacationPlanAndCreate ? "" : "2xl:grid-cols-4"
                    }`}
                >
                    {myCalendarStore.monthList.map((month) => (
                        <Month
                            key={month}
                            year={myCalendarStore.date.year()}
                            month={month}
                            events={myCalendarStore.events}
                        />
                    ))}
                </div>
            </div>
        );
    } else if (myCalendarStore.view === "month") {
        return (
            <div>
                <div className="mb-5 flex justify-center w-full">
                    <MonthSelector />
                </div>
                <div>
                    <Month
                        year={myCalendarStore.date.year()}
                        month={myCalendarStore.date.month()}
                        showMonth={false}
                        events={myCalendarStore.events}
                    />
                </div>
            </div>
        );
    }
    return null;
});

const MyCalendar = ({
    year = dayjs().year(),
    month = dayjs().month(),
    view = "year",
    viewList = [],
    showToday = true,
    fetchList = [],
    showTodayEvents = true,
    vacationPlanAndCreate = false,
    eventsFilter = true,
    calendarStoreSetter,
}: MyCalendarInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [myCalendarStore] = useState(
        () =>
            new MyCalendarStore(
                domainStore,
                year,
                month,
                view,
                viewList,
                showToday,
                fetchList,
                showTodayEvents,
                vacationPlanAndCreate,
                eventsFilter,
                domainStore.localRoles.department_manager
            )
    );
    const [localDate, setLocalDate] = useState<LocalDateInterface>({ date: myCalendarStore.date, behavior: "auto" });
    const [vacationObjectId, setVacationObjectId] = useState<number | null>(null);

    useEffect(() => {
        if (calendarStoreSetter) calendarStoreSetter(myCalendarStore);
    }, []);

    return (
        <div className="my-calendar">
            <MyCalendarContext.Provider value={{ myCalendarStore }}>
                <ManagerViewContext.Provider value={{ localDate, setLocalDate, vacationObjectId, setVacationObjectId }}>
                    {(myCalendarStore.viewList.length > 1 || showToday || eventsFilter) &&
                        !myCalendarStore.managerMode && (
                            <div className="mb-4">
                                <Tools />
                            </div>
                        )}
                    <div
                        className={`flex flex-col gap-6 ${
                            myCalendarStore.vacationPlanAndCreate ? "2xl:flex-row-reverse 2xl:justify-between" : ""
                        }`}
                    >
                        {myCalendarStore.vacationPlanAndCreate && (
                            <div className={`${myCalendarStore.managerMode ? "flex-grow hidden 2xl:block" : ""}`}>
                                {myCalendarStore.managerMode ? <SubordinatesList /> : <VacationPlanBlock />}
                            </div>
                        )}

                        <div className={`${!myCalendarStore.managerMode ? "flex-grow" : ""}`}>
                            {!myCalendarStore.managerMode ? <CalendarView /> : <ManagerView />}
                        </div>
                    </div>
                </ManagerViewContext.Provider>
            </MyCalendarContext.Provider>
        </div>
    );
};

export default observer(MyCalendar);
