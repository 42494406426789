import { useContext, useState } from "react";
import { CommentInterface } from "../interfaces";
import dayjs from "dayjs";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import EditableText from "./comments/EditableText";
import { UpperLevelCommentStore, LowerLevelCommentStore } from "../store";
import AuthContext from "../../../../context/AuthContext";
import NewCommentInput from "./comments/NewCommentInput";
import { observer } from "mobx-react-lite";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import BlockIcon from "@mui/icons-material/Block";
import { motion } from "framer-motion";
import ProfileImageSkeleton from "../../../../components/skeletons/ProfileImageSkeleton";
import "../styles.css";
import NavLink from "../../../../components/general/NavLink";
import { AuthContextType } from "../../../../context/AuthContext";

interface CommentBodyInterface {
    comment: CommentInterface;
    store: UpperLevelCommentStore | LowerLevelCommentStore;
}

const CommentBody = ({ comment, store }: CommentBodyInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openBlock, setOpenBlock] = useState<boolean>(false);
    const [cursorOver, setCursorOver] = useState<boolean>(false);

    const variants = {
        simple: {
            opacity: 0,
        },
        hover: {
            opacity: 1,
        },
    };

    return (
        <motion.div
            onHoverStart={() => setCursorOver(true)}
            onHoverEnd={() => setCursorOver(false)}
            className="comment-font"
        >
            <div className="mb-[12px] flex items-center">
                {comment.user.profile && (
                    <div className="mr-3 shrink-0">
                        <NavLink href={`/users/profile/${comment.user.id}`}>
                            <ProfileImageSkeleton
                                image={comment.user.profile.thumbnail}
                                className="w-[35px] text-[35px]"
                            />
                        </NavLink>
                    </div>
                )}
                <div>
                    <div className="font-semibold">
                        <NavLink href={`/users/profile/${comment.user.id}`}>
                            {comment.user.last_name} {comment.user.first_name}
                        </NavLink>
                    </div>
                    <span className="block xl:hidden">{dayjs(comment.created_at).fromNow()}</span>
                </div>
                {store.type === "lower" && (
                    <div className="hidden items-center xl:flex">
                        <span className="ml-4">ответил</span>
                        <div className="ml-4 flex items-center">
                            {comment.parent?.user.profile && (
                                <div className="mr-3">
                                    <NavLink href={`/users/profile/${comment.parent?.user.id}`}>
                                        <ProfileImageSkeleton
                                            image={comment.parent?.user.profile.thumbnail}
                                            className="w-[35px] text-[35px]"
                                        />
                                    </NavLink>
                                </div>
                            )}
                            <span className="font-semibold">
                                <NavLink href={`/users/profile/${comment.parent?.user.id}`}>
                                    {comment.parent?.user.last_name} {comment.parent?.user.first_name}
                                </NavLink>
                            </span>
                        </div>
                    </div>
                )}
                <span className="hidden ml-5 xl:block">{dayjs(comment.created_at).fromNow()}</span>
                {comment.edited && (
                    <div className="ml-5 flex items-center" title="Редактировано">
                        <EditSharpIcon color="secondary" fontSize="small" />
                    </div>
                )}
            </div>
            {comment.blocked || comment.deleted ? (
                <div className="italic opacity-60">
                    {comment.deleted ? "Комментарий удалён" : "Комментарий заблокирован модератором"}
                </div>
            ) : (
                <div>
                    <EditableText store={store} comment={comment} />
                    <div>
                        {store.newCommentExpanded && (
                            <div className="mt-5">
                                <NewCommentInput store={store} />
                            </div>
                        )}
                        <div className="mt-[12px] flex gap-3 items-center">
                            {!store.newCommentExpanded && (
                                <button className="text-[#A8A6A6]" onClick={() => store.setNewCommentExpanded(true)}>
                                    Ответить
                                </button>
                            )}
                            <div className="hidden xl:block">
                                <motion.div
                                    className="flex gap-3 items-center"
                                    variants={variants}
                                    animate={cursorOver ? "hover" : "simple"}
                                    initial={false}
                                >
                                    {domainStore.user.id === comment.user.id && !store.editMode && (
                                        <button className="text-[#A8A6A6]" onClick={() => store.setEditMode(true)}>
                                            Редактировать
                                        </button>
                                    )}
                                    {domainStore.user.id === comment.user.id && (
                                        <button
                                            title="Удалить"
                                            className="flex justify-center items-center"
                                            onClick={() => setOpenDelete(true)}
                                        >
                                            <CloseIcon fontSize="small" color="error" />
                                        </button>
                                    )}
                                    {domainStore.isModerator && comment.user.id !== domainStore.user.id && (
                                        <button
                                            title="Заблокировать"
                                            className="flex justify-center items-center"
                                            onClick={() => setOpenBlock(true)}
                                        >
                                            <BlockIcon fontSize="small" color="error" />
                                        </button>
                                    )}
                                </motion.div>
                            </div>
                            <div className="flex gap-3 items-center xl:hidden">
                                {domainStore.user.id === comment.user.id && !store.editMode && (
                                    <button className="text-[#A8A6A6]" onClick={() => store.setEditMode(true)}>
                                        Редактировать
                                    </button>
                                )}
                                {domainStore.user.id === comment.user.id && (
                                    <button
                                        title="Удалить"
                                        className="flex justify-center items-center"
                                        onClick={() => setOpenDelete(true)}
                                    >
                                        <CloseIcon fontSize="small" color="error" />
                                    </button>
                                )}
                                {domainStore.isModerator && comment.user.id !== domainStore.user.id && (
                                    <button
                                        title="Заблокировать"
                                        className="flex justify-center items-center"
                                        onClick={() => setOpenBlock(true)}
                                    >
                                        <BlockIcon fontSize="small" color="error" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <Dialog id="deleteModal" open={openDelete} onClose={() => setOpenDelete(false)}>
                        <DialogContent>
                            <DialogContentText>Вы уверены, что хотите удалить комментарий?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDelete(false)} color="secondary">
                                Отмена
                            </Button>
                            <Button
                                color="signature"
                                onClick={() => {
                                    store.deleteOrBlockComment("delete");
                                    setOpenDelete(false);
                                }}
                            >
                                Подтвердить
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog id="blockModal" open={openBlock} onClose={() => setOpenBlock(false)}>
                        <DialogContent>
                            <DialogContentText>Вы уверены, что хотите заблокировать комментарий?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenBlock(false)} color="secondary">
                                Отмена
                            </Button>
                            <Button
                                color="signature"
                                onClick={() => {
                                    store.deleteOrBlockComment("block");
                                    setOpenBlock(false);
                                }}
                            >
                                Подтвердить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </motion.div>
    );
};

export default observer(CommentBody);
