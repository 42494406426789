import { makeAutoObservable } from "mobx";
import { DAYS_IN_WEEK } from "../../common/constants";
import dayjs, { Dayjs } from "dayjs";
import { EventInterface } from "../../store";

export interface DateInterface {
    date: Dayjs;
    events: Array<EventInterface>;
}

export default class MonthStore {
    year: number;
    month: number;
    monthFirstDate: Dayjs;
    monthData: Array<Array<DateInterface | null>> = [];
    events: Array<EventInterface>;

    constructor(year: number, month: number, events: Array<EventInterface>) {
        this.year = year;
        this.month = month;
        this.monthFirstDate = dayjs({ year, month });
        this.events = events;
        this.monthData = this.getMonthData();
        makeAutoObservable(this);
    }

    getDayOfWeek(date: Dayjs) {
        const dayOfWeek = date.day();
        if (dayOfWeek === 0) return 6;
        return dayOfWeek - 1;
    }

    getMonthData(): Array<Array<DateInterface | null>> {
        const result: Array<Array<DateInterface | null>> = [];
        const daysInMonth = this.monthFirstDate.daysInMonth();
        const monthStartsOn = this.getDayOfWeek(this.monthFirstDate);
        let day = 1;
        for (let i = 0; i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK; i++) {
            result[i] = [];

            for (let j = 0; j < DAYS_IN_WEEK; j++) {
                if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
                    result[i][j] = null;
                } else {
                    const todayEvents = this.events.filter((ev) => {
                        if (ev.type === "birthdate") {
                            if (ev.datetime.d === day && ev.datetime.M === this.month + 1) {
                                return true;
                            }
                        } else {
                            if (
                                ev.datetime.d === day &&
                                ev.datetime.M === this.month + 1 &&
                                ev.datetime.y === this.year
                            ) {
                                return true;
                            }
                        }
                        return false;
                    });
                    result[i][j] = {
                        date: dayjs({ year: this.year, month: this.month, day: day++ }),
                        events: todayEvents,
                    };
                }
            }
        }
        return result;
    }

    setMonthData(data: Array<Array<DateInterface | null>>) {
        this.monthData = data;
    }
}
