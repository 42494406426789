import { BaseSyntheticEvent, useEffect, useState, useContext, Fragment } from "react";
import { NodeDatumInterface } from "../interfaces";
import { motion } from "framer-motion";
import PersonIcon from "@mui/icons-material/Person";
import { mediaNode } from "../utils";
import "./treeElement.css";
import { observer } from "mobx-react-lite";
import AuthContext from "../../../../context/AuthContext";
import { AuthContextType } from "../../../../context/AuthContext";

interface TreeElementInterface {
    nodeDatum: NodeDatumInterface;
    toggleNode: Function;
    search: any;
    setRootNodeXCollapsed: Function;
    centeringTransitionDuration: number;
}

const TreeElement = ({
    nodeDatum,
    toggleNode,
    search,
    setRootNodeXCollapsed,
    centeringTransitionDuration,
}: TreeElementInterface) => {
    const circleD = 50;
    const [nodeSettings, setNodeSettings] = useState(mediaNode[0].params);
    const [active, setActive] = useState<boolean>(false);
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    const variants = {
        initial: { backgroundColor: domainStore.theme === "dark" ? "#717171" : "#ffffff" },
        active: {
            backgroundColor:
                domainStore.theme === "dark" ? ["#717171", "#ba9ffb", "#717171"] : ["#ffffff", "#FDF07F", "#ffffff"],
        },
    };

    const redirectToUser = (e: BaseSyntheticEvent, id: number) => {
        e.preventDefault();
        window.open(`/users/profile/${id}`);
    };

    const handleNodeToggle = (e: BaseSyntheticEvent) => {
        if (nodeDatum.__rd3t.depth === 0) setRootNodeXCollapsed(!nodeDatum.__rd3t.collapsed);
        toggleNode();
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const windowWidth = entries[0].contentRect.width;

            let currRes = 0;
            let idx = 0;
            for (const i of mediaNode) {
                if (windowWidth >= i.threshold) {
                    currRes = idx;
                } else {
                    break;
                }
                idx++;
            }
            setNodeSettings(mediaNode[currRes].params);
        });
        resizeObserver.observe(document.body);
    }, []);

    useEffect(() => {
        if (
            nodeDatum &&
            search.length &&
            nodeDatum.type === search[1] &&
            (nodeDatum.id === search[0] || nodeDatum.user?.id === search[0])
        ) {
            if (nodeDatum.__rd3t.depth === 0 && !nodeDatum.__rd3t.collapsed) {
                toggleNode();
                setTimeout(() => {
                    toggleNode();
                }, centeringTransitionDuration);
            }

            // если узел не раскрыт
            if (nodeDatum.__rd3t.collapsed) {
                toggleNode();
            }
            setActive(true);
            setTimeout(() => {
                setActive(false);
            }, 5000);
        }
    }, [search]);

    const renderInnerContent = () => {
        if (nodeDatum.type === "company" || nodeDatum.type === "department") {
            return (
                <motion.div
                    initial="initial"
                    animate={active ? "active" : "initial"}
                    variants={variants}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="w-full h-full rounded-full flex px-5 py-3 items-center gap-2"
                    // style={{ marginLeft: nodeDatum.children?.length > 0 ? -10 : 0 }}
                >
                    {nodeDatum.manager && nodeDatum.manager.photo ? (
                        <a
                            href={`/users/profile/${nodeDatum.manager?.id as number}`}
                            onClick={(e) => redirectToUser(e, nodeDatum.manager?.id as number)}
                            className="shrink-0"
                        >
                            <img
                                style={{ width: circleD, height: circleD }}
                                className="rounded-full"
                                src={nodeDatum.manager.photo}
                            />
                        </a>
                    ) : (
                        <div>
                            <div
                                style={{ width: circleD, height: circleD }}
                                className="rounded-full bg-gray-300 flex items-center justify-center text-white"
                            >
                                <PersonIcon className="stroke-none" fontSize="large" />
                            </div>
                        </div>
                    )}
                    {nodeDatum.manager ? (
                        <a
                            className="text-[14px]"
                            href={`/users/profile/${nodeDatum.manager?.id as number}`}
                            onClick={(e) => redirectToUser(e, nodeDatum.manager?.id as number)}
                        >
                            <div className="font-bold node-user-text" title={nodeDatum.name}>
                                {nodeDatum.name}
                            </div>
                            <div
                                className="node-user-text"
                                title={nodeDatum.manager.name}
                                onClick={(e) => redirectToUser(e, nodeDatum.manager?.id as number)}
                            >
                                {nodeDatum.manager.name}
                            </div>
                        </a>
                    ) : (
                        <div className="text-[14px]">
                            <div className="font-bold node-user-text" title={nodeDatum.name}>
                                {nodeDatum.name}
                            </div>
                        </div>
                    )}
                </motion.div>
            );
        } else if (nodeDatum.type === "user") {
            return (
                <motion.div
                    initial="initial"
                    animate={active ? "active" : "initial"}
                    variants={variants}
                    transition={{ ease: "easeOut", duration: 2 }}
                    className="w-full h-full rounded-full flex px-5 py-3 items-center gap-3"
                    // style={{ marginLeft: nodeDatum.children?.length > 0 ? -10 : 0 }}
                >
                    <a
                        href={`/users/profile/${nodeDatum.user?.id as number}`}
                        onClick={(e) => redirectToUser(e, nodeDatum.user?.id as number)}
                        className="shrink-0"
                    >
                        {nodeDatum.user?.photo ? (
                            <img
                                style={{ width: circleD, height: circleD }}
                                className="rounded-full"
                                src={nodeDatum.user.photo}
                            />
                        ) : (
                            <div
                                style={{ width: circleD, height: circleD }}
                                className="rounded-full bg-gray-300 flex items-center justify-center text-white"
                            >
                                <PersonIcon className="stroke-none" fontSize="large" />
                            </div>
                        )}
                    </a>
                    <a
                        className="text-[14px]"
                        href={`/users/profile/${nodeDatum.user?.id as number}`}
                        onClick={(e) => redirectToUser(e, nodeDatum.user?.id as number)}
                    >
                        <div
                            className={`font-bold text-nowrap overflow-hidden text-ellipsis ${
                                nodeDatum.children?.length
                                    ? "max-w-[110px] lg:max-w-[180px]"
                                    : "max-w-[130px] lg:max-w-[200px]"
                            }`}
                            title={nodeDatum.user?.name}
                        >
                            {nodeDatum.user?.name}
                        </div>
                        {nodeDatum.user?.position && (
                            <div
                                className={`text-nowrap overflow-hidden text-ellipsis ${
                                    nodeDatum.children?.length
                                        ? "max-w-[110px] lg:max-w-[180px]"
                                        : "max-w-[130px] lg:max-w-[200px]"
                                }`}
                                title={nodeDatum.user?.position}
                            >
                                {nodeDatum.user?.position}
                            </div>
                        )}
                    </a>
                </motion.div>
            );
        }
    };

    return (
        <Fragment>
            <foreignObject
                {...(nodeSettings as any)}
                className={`${nodeDatum.__rd3t.depth === 0 ? "!translate-x-10 lg:!translate-x-0" : ""}`}
            >
                <div className="flex items-center hover:cursor-default">
                    {nodeDatum.__rd3t.depth === 0 && (
                        <div className="h-full w-full flex items-center justify-center">
                            <div className="bg-white dark:dark-surface-500 rounded-full py-1 px-2 lg:px-9">
                                <img className="max-h-16" src="/AvilexLogo.svg" />
                            </div>
                        </div>
                    )}
                    {nodeDatum.children?.length > 0 && nodeDatum.__rd3t.depth !== 0 && (
                        <div
                            className="rounded-full bg-gray-300 dark:dark-surface-400 -me-[1px] hover:cursor-pointer text-white w-10 h-10 shrink-0"
                            onClick={handleNodeToggle}
                        >
                            <div className="w-full h-full flex justify-center items-center text-[30px] leading-none">
                                {nodeDatum.__rd3t.collapsed ? "+" : "-"}
                            </div>
                        </div>
                    )}
                    {nodeDatum.__rd3t.depth !== 0 && renderInnerContent()}                    
                </div>
            </foreignObject>
        </Fragment>
    );
};

export default observer(TreeElement);
