import { useContext, useState } from "react";
import { ProfileContext, ProfileContextInterface } from "../../context";
import { observer } from "mobx-react-lite";
import SettingsIcon from "@mui/icons-material/Settings";
import { Popover, ListItem, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import SettingDialog from "./components/SettingDialog";
import PhotoDialog from "./components/PhotoDialog";
import AuthContext from "../../../../context/AuthContext";
import { motion } from "framer-motion";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteDialog from "./components/DeleteDialog";
import "./styles.css";
import { MyProfileStore, UserProfileStore } from "../../stores";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect } from "react";
import { AuthContextType } from "../../../../context/AuthContext";

const ProfilePhoto = observer(
    ({ store, className = "" }: { store: MyProfileStore | UserProfileStore; className?: string }) => {
        const [loaded, setLoaded] = useState<boolean>(false);
        const [error, setError] = useState<boolean>(false);

        useEffect(() => {
            setLoaded(false);
            setError(false);

            const imageLoader = new Image();
            imageLoader.src = store.profile?.thumbnail as string;
            imageLoader.onload = () => {
                setTimeout(() => {
                    setLoaded(true);
                }, 0);
            };
            imageLoader.onerror = () => {
                setError(true);
            };
        }, [store.profile?.thumbnail]);

        if (loaded) {
            return (
                <img
                    src={store.profile?.thumbnail}
                    className={`rounded-full border-solid border dark:border-[#717171] aspect-square ${className}`}
                />
            );
        } else if (error || !store.profile?.thumbnail) {
            return (
                <div className={`bg-gray-300 rounded-full flex items-center justify-center aspect-square ${className}`}>
                    <PersonIcon className="text-white !text-[0.7em]" />
                </div>
            );
        }
        return <div className={`aspect-square bg-slate-200 animate-pulse rounded-full ${className}`} />;
    }
);

const ProfileCard = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { store } = useContext(ProfileContext) as ProfileContextInterface;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    const handlePhotoEdit = (e: any) => {
        setAnchorEl(e.target);
    };

    return (
        <div className="p-[21px] gap-7 bg-white dark:dark-surface-300 w-full flex flex-col justify-between rounded-[28px] relative xl:p-[34px]">
            {store.name === "myProfile" && (
                <div className="absolute top-[21px] right-[21px] xl:right-[34px] xl:top-[37px]">
                    <motion.div
                        className="p-[9px] cursor-pointer rounded-full bg-[#F4F4F6] dark:dark-surface-400 flex justify-center items-center"
                        title="Редактирование"
                        onClick={() => setDialogOpen(true)}
                        whileHover={{ rotate: "90deg" }}
                        transition={{ ease: "easeOut" }}
                    >
                        <SettingsIcon fontSize="medium" />
                    </motion.div>
                    <SettingDialog open={dialogOpen} setOpen={setDialogOpen} />
                    <PhotoDialog />
                    <DeleteDialog />
                </div>
            )}
            <div className="flex flex-col items-center">
                {store.profile && (
                    <div className="relative">
                        <ProfilePhoto store={store} className="my-avatar" />
                        {store.name === "myProfile" && domainStore.user.id === store.profile.user_id && (
                            <div>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileHover={{ opacity: 0.43 }}
                                    className="absolute w-full h-full top-0 left-0 rounded-full bg-black hover:cursor-pointer flex justify-center items-center"
                                    onClick={handlePhotoEdit}
                                    title="Изменить фото"
                                >
                                    <AddAPhotoOutlinedIcon className="scale-[4] text-white" />
                                </motion.div>
                                <Popover open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
                                    <List dense>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    if ("setPhotoDialogOpen" in store) store.setPhotoDialogOpen(true);

                                                    setAnchorEl(null);
                                                }}
                                            >
                                                <ListItemIcon className="profile-list-item-icon">
                                                    <EditIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Редактировать фото" />
                                            </ListItemButton>
                                        </ListItem>
                                        {store.profile.photo && (
                                            <ListItem disablePadding>
                                                <ListItemButton
                                                    onClick={() => {
                                                        if ("setDeleteDialogOpen" in store)
                                                            store.setDeleteDialogOpen(true);

                                                        setAnchorEl(null);
                                                    }}
                                                >
                                                    <ListItemIcon className="profile-list-item-icon">
                                                        <DeleteForeverIcon color="error" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Удалить фото"
                                                        primaryTypographyProps={{ color: "red" }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )}
                                    </List>
                                </Popover>
                            </div>
                        )}
                    </div>
                )}
                <span className="mt-[13px] text-[20px] leading-[27px] font-bold xl:mt-[23px] text-center xl:text-[30px] xl:leading-[41px]">
                    {store.profile?.user.last_name} {store.profile?.user.first_name}
                </span>
                <span className="mt-1 text-[15px] leading-[20px] text-center font-medium xl:text-[17px] xl:leading-[23px]">
                    {store.profile?.position?.name}
                </span>
            </div>
            <div>
                <div className="text-[17px] mb-[7px] leading-[105%] font-bold text-[#949494] xl:mb-[12px]">О себе</div>
                <div className="max-h-[300px] overflow-y-auto">
                    {store.profile?.description ? (
                        <span className="font-medium leading-[20px] text-[15px]">{store.profile?.description}</span>
                    ) : (
                        <span className="italic text-black/70 dark:text-white/70">Раздел пока не заполнен</span>
                    )}
                </div>
            </div>
            <div>
                <div className="text-[17px] leading-[105%] font-bold mb-[12px] text-[#949494]">Интересы</div>
                <div className="">
                    {store.profile?.user.tags.length ? (
                        <div className="flex gap-3 max-h-[200px] overflow-y-auto flex-wrap font-semibold">
                            {store.profile?.user.tags.map((el) => (
                                <div
                                    key={el.id}
                                    style={{ backgroundColor: `#${el.color}` }}
                                    className="rounded-xl px-2 py-1 text-sm dark:!bg-[#9171F8]"
                                >
                                    #{el.name.toUpperCase()}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <span className="italic text-black/70 dark:text-white/70">Раздел пока не заполнен</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(ProfileCard);
