import { makeAutoObservable } from "mobx";
import { jwtDecode } from "jwt-decode";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import axios from "axios";
import { API_URL } from "../http";
import { BaseSyntheticEvent } from "react";
import { enqueueSnackbar } from "notistack";

interface ProfileInterface {
    thumbnail: string;
}

interface UserInterface {
    id?: number;
    profile?: ProfileInterface;
}

interface LocalRolesInterface {
    department_manager: boolean;
}

class DomainStore {
    userTokenData = localStorage.getItem("authTokens")
        ? jwtDecode(JSON.parse(localStorage.getItem("authTokens")!).access)
        : {};
    user: UserInterface = {};
    isAuth = !!localStorage.getItem("authTokens");
    isLoading = false;
    isModerator = false;
    userIsLoading = true;
    theme = localStorage.getItem("theme");
    localRoles: LocalRolesInterface = { department_manager: false };

    thumbnailLoading: boolean = true;
    thumbnailError: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth = (bool: boolean) => {
        this.isAuth = bool;
    };

    setModerator(bool: boolean) {
        this.isModerator = bool;
    }

    setUserIsLoading(bool: boolean) {
        this.userIsLoading = bool;
    }

    setTokenData = (data: object) => {
        this.userTokenData = data;
    };

    setUser = (data: object) => {
        this.user = data;
    };

    setLoading = (bool: boolean) => {
        this.isLoading = bool;
    };

    setThumbnailLoading = (bool: boolean) => {
        this.thumbnailLoading = bool;
    };

    setThumbnailError = (bool: boolean) => {
        this.thumbnailError = bool;
    };

    setTheme = (theme: string | null) => {
        this.theme = theme;
    };

    setLocalRoles = (roles: LocalRolesInterface) => {
        this.localRoles = roles;
    };

    loadThumbnail = (src: string) => {
        this.setThumbnailLoading(true);
        this.setThumbnailError(false);

        const img = new Image();
        img.src = src;
        img.onload = () => {
            setTimeout(() => {
                this.setThumbnailLoading(false);
            }, 0);
        };
        img.onerror = () => {
            this.setThumbnailLoading(false);
            this.setThumbnailError(true);
        };
    };

    login = async (username: string, password: string) => {
        try {
            const response = await AuthService.login(username, password);
            localStorage.setItem("authTokens", JSON.stringify(response.data));
            this.setAuth(true);
            this.setTokenData(jwtDecode(response.data.access));
            return response;
        } catch (e: any) {
            return e.response;
        }
    };

    loginBySSO = async (next: string = "/") => {
        try {
            const response = await AuthService.loginBySSO(next);
            return response;
        } catch (e: any) {
            return e.response;
        }
    };

    handleTokenReceive = (access: string, refresh: string) => {
        localStorage.setItem("authTokens", JSON.stringify({ access, refresh }));
        this.setAuth(true);
        this.setTokenData(jwtDecode(access));
    };

    logout = () => {
        this.setAuth(false);
        this.setTokenData({});
        localStorage.removeItem("authTokens");
    };

    checkAuth = async () => {
        this.setLoading(true);
        try {
            const initial_token = JSON.parse(localStorage.getItem("authTokens")!);
            const refreshResponse = await axios.post(`${API_URL}/token/refresh/`, { refresh: initial_token.refresh });

            const userResponse = await UserService.getMe();
            this.setUser(userResponse.data);
            this.setTheme(userResponse.data.usersettings.theme);
            this.setModerator(
                !!userResponse.data.permissions.filter((el: string) => el === "conflux.news_moderator_role").length
            );
            this.setLocalRoles(userResponse.data.local_roles);

            this.loadThumbnail(userResponse.data.profile.thumbnail);

            setTimeout(() => {
                this.setUserIsLoading(false);
            }, 0);

            initial_token.access = refreshResponse.data.access;
            localStorage.setItem("authTokens", JSON.stringify(initial_token));
            localStorage.setItem("theme", userResponse.data.usersettings.theme);
            this.setAuth(true);
            this.setTokenData(jwtDecode(refreshResponse.data.access));
        } catch (e) {
            console.error(e);
            localStorage.removeItem("authTokens");
            window.location.href = "/login";
        } finally {
            this.setLoading(false);
        }
    };

    changeTheme = async (e: BaseSyntheticEvent) => {
        await UserService.changeTheme({ theme: e.target.checked ? "dark" : "light" })
            .then((resp) => {
                this.setTheme(resp.data.theme);
                localStorage.setItem("theme", resp.data.theme);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка смены темы");
            });
    };
}

export default DomainStore;
