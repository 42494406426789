import { ChangeEvent, useContext, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { Checkbox, FormControlLabel } from "@mui/material";
import ExpandIcon from "@mui/icons-material/Expand";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import "./styles.css";

interface ToolsInterface {
    allUsersExpanded: boolean;
    setAllUsersExpanded: (a: boolean) => void;
}

const Tools = ({ allUsersExpanded, setAllUsersExpanded }: ToolsInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [onlyUnprocessed, setOnlyUnprocessed] = useState<boolean>(false);

    const handleExpandToggle = () => {
        setAllUsersExpanded(!allUsersExpanded);
    };

    const handleUnprocessedChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            myCalendarStore.changeSubordinatesFilter("unprocessed");
        } else {
            myCalendarStore.changeSubordinatesFilter("all");
        }
        setOnlyUnprocessed(e.target.checked);
    };

    return (
        <div className="flex items-center gap-3 flex-wrap">
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={myCalendarStore.loadingSubordinates}
                        checked={onlyUnprocessed}
                        onChange={handleUnprocessedChange}
                    />
                }
                label={<span className="tool-text-size">Только несогласованные</span>}
            />
            <button
                className="flex items-center justify-center gap-1 tool-text-size disabled:opacity-50"
                onClick={handleExpandToggle}
                disabled={myCalendarStore.loadingSubordinates}
            >
                {allUsersExpanded ? <UnfoldLessIcon /> : <ExpandIcon />}
                <span className="">{allUsersExpanded ? "Свернуть" : "Показать"} всё</span>
            </button>
        </div>
    );
};

export default Tools;
