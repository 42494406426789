import MainLayout from "../../layouts/MainLayout";
import MyCalendar from "../../components/MyCalendar";
import InfoIcon from "@mui/icons-material/Info";
import { useState, useContext, BaseSyntheticEvent } from "react";
import VacationRulesDialog from "../../components/MyCalendar/components/VacationRulesDialog";
import MyCalendarStore from "../../components/MyCalendar/store";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import AuthContext from "../../context/AuthContext";
import { AuthContextType } from "../../context/AuthContext";
import { observer } from "mobx-react-lite";

const Vacation = () => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [myCalendarStore, setMeCalendarStore] = useState<null | MyCalendarStore>(null);
    const handleModalOpen = () => {
        setDialogOpen(true);
    };

    const handleModalClose = () => {
        setDialogOpen(false);
    };
    const handleModeChange = (e: BaseSyntheticEvent) => {
        if (myCalendarStore) {
            if (!e.target.checked) {
                myCalendarStore.setCalendarRendered(false);
            }
            myCalendarStore.setManagerMode(!myCalendarStore.managerMode);
        }
    };

    return (
        <MainLayout>
            <div className="flex justify-between items-center">
                <span className="avx-page-h">Отпуск</span>
                <div className="flex items-center gap-2">
                    {domainStore.localRoles.department_manager && (
                        <div className="flex items-center">
                            <Switch checked={!!myCalendarStore?.managerMode} onChange={handleModeChange} />
                            <label className="text-sm">Режим руководителя</label>
                        </div>
                    )}
                    <div className="2xl:hidden">
                        <button onClick={handleModalOpen}>
                            <InfoIcon />
                        </button>
                        <VacationRulesDialog open={dialogOpen} onClose={handleModalClose} />
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <MyCalendar
                    view="year"
                    viewList={["year"]}
                    fetchList={["vacation", "holidays"]}
                    showToday={false}
                    showTodayEvents={false}
                    vacationPlanAndCreate
                    calendarStoreSetter={setMeCalendarStore}
                />
            </div>
        </MainLayout>
    );
};

export default observer(Vacation);
