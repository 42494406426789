import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { CommentsStore } from "../../store";
import { observer } from "mobx-react-lite";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
} from "@floating-ui/react";
import EmojiPicker, { EmojiStyle, EmojiClickData } from "emoji-picker-react";
import { emojiCategories } from "../../../../../components/inputs/TipTapEditor/utils";
import { Theme } from "emoji-picker-react";
import { useContext } from "react";
import AuthContext from "../../../../../context/AuthContext";
import { AuthContextType } from "../../../../../context/AuthContext";

interface NewCommentInterface {
    store: CommentsStore;
}

const NewComment = ({ store }: NewCommentInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [theme, setTheme] = useState(domainStore.theme === "dark" ? Theme.DARK : Theme.LIGHT);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip({ fallbackAxisSideDirection: "end" }), shift()],
        whileElementsMounted: autoUpdate,
        placement: "right-end",
    });

    const click = useClick(context);
    const dismiss = useDismiss(context, {
        outsidePress(event: any) {
            if (event.target.matches(".emoji-comment *")) return false;
            return true;
        },
    });
    const role = useRole(context);
    const { getFloatingProps } = useInteractions([click, dismiss, role]);

    const handleEmojiMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(!isOpen);
        setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
        }, 10);
    };

    const handleCommentSend = () => {
        if (!store.loadingMainComments && !store.loadingMore && store.newCommentText.trim())
            store.sendComment(true, null);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            handleCommentSend();
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        store.setNewCommentText(e.target.value.trimStart());
    };

    const handleEmojiClick = (emojiData: EmojiClickData, event: any) => {
        if (inputRef.current) {
            const cursor = inputRef.current.selectionStart;
            store.setNewCommentText(
                store.newCommentText.slice(0, cursor) + emojiData.emoji + store.newCommentText.slice(cursor)
            );
            const newCursor = cursor + emojiData.emoji.length;
            setTimeout(() => {
                if (inputRef.current) inputRef.current.setSelectionRange(newCursor, newCursor);
            }, 10);
            inputRef.current.focus();
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = inputRef.current.scrollHeight + "px";
        }
    }, [store.newCommentText]);

    return (
        <div className="px-3 py-2 rounded-xl flex items-center bg-[#F1F1F1] dark:dark-surface-500 w-full xl:py-[7px] xl:px-[20px] xl:rounded-xl">
            <div className="emoji-comment w-full flex items-center border-solid border-r-2 border-[#B3B3B3] dark:border-white min-h-[2em] pr-[18px]">
                <textarea
                    className="text-sm text-[#535353] dark:text-white dark:placeholder:text-white/80 w-full bg-inherit outline-none xl:text-[17px] resize-none"
                    placeholder="Введите комментарий"
                    value={store.newCommentText}
                    onChange={handleTextareaChange}
                    maxLength={2000}
                    rows={1}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                />
                <div>
                    <button
                        className="text-gray-600 dark:text-white"
                        title="Эмодзи"
                        onClick={handleEmojiMenuClick}
                        ref={refs.setReference}
                    >
                        <SentimentSatisfiedAltIcon />
                    </button>
                    {isOpen && (
                        <FloatingFocusManager context={context} modal={false} closeOnFocusOut={false}>
                            <div className="z-20" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                                <EmojiPicker
                                    theme={theme}
                                    skinTonesDisabled={true}
                                    searchPlaceHolder="Поиск"
                                    previewConfig={{ showPreview: false }}
                                    categories={emojiCategories as any}
                                    searchDisabled
                                    className="!w-[300px] !h-[230px] xl:!w-[400px] xl:!h-[270px]"
                                    emojiStyle={EmojiStyle.NATIVE}
                                    emojiVersion={"5.0"}
                                    onEmojiClick={handleEmojiClick}
                                />
                            </div>
                        </FloatingFocusManager>
                    )}
                </div>
            </div>
            <button
                className="avilex-color pl-[18px] dark:dark-primary-text-500"
                title="Отправить"
                onClick={handleCommentSend}
            >
                <SendIcon />
            </button>
        </div>
    );
};

export default observer(NewComment);
