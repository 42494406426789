import { useContext, useEffect, useRef, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import "./styles.css";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { vacationTypeModeMap } from "../../store";
import { motion, useMotionValue } from "framer-motion";
import SubmitPlanButton from "./components/SubmitPlanButton";
import VacationBubble from "./components/VacationBubble";
import VacationImage from "./components/VacationImage";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import VacationRulesDialog from "../VacationRulesDialog";
import ModeSwitch from "./components/ModeSwitch";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const VacationPlanBlock = () => {
    const theme = useTheme();
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [expanded, setExpanded] = useState<boolean>(true);
    const [sliderWidth, setSliderWidth] = useState<number>(0);
    const sliderRef = useRef<HTMLDivElement>(null);
    const dragX = useMotionValue(0);
    const desktopMatches = useMediaQuery(theme.breakpoints.up("xl"));
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const variants = {
        expanded: {
            height: "fit-content",
            opacity: 1,
            marginTop: 0,
        },
        collapsed: {
            height: 0,
            opacity: 0,
            marginTop: -10,
        },
    };

    // Изменение режима отпуска для перехода на нужный год
    useEffect(() => {
        if (
            myCalendarStore.vacationMode === "planMode" &&
            myCalendarStore.date.year() !== dayjs().add(1, "year").year()
        ) {
            myCalendarStore.setDate(dayjs().add(1, "year"));
        }
    }, [myCalendarStore.vacationMode]);

    // Видимость в зависимости от режима
    useEffect(() => {
        myCalendarStore.setVacationList(
            myCalendarStore.vacationList.map((el) => ({
                ...el,
                hidden: el.type !== vacationTypeModeMap[myCalendarStore.vacationMode],
            }))
        );
    }, [myCalendarStore.vacationMode]);

    // Изменение текущей даты (года)
    useEffect(() => {
        if (myCalendarStore.date.year() !== dayjs().add(1, "year").year()) {
            myCalendarStore.setVacationMode("createMode");
        }
    }, [myCalendarStore.date]);

    // Изменение списка отпусков
    useEffect(() => {
        if (!sliderRef.current) return;
        if (sliderRef.current.scrollWidth === sliderRef.current.offsetWidth && dragX.get() < 0) {
            // для корректного удаления элемента из списка необходимо сделать это (причина не найдена)
            dragX.set(0);
            setSliderWidth(0);

            // почему-то есть задержка в расчёте scrollWidth при удалении элемента. Единственный найденный обход - таймаут
            setTimeout(() => {
                if (!sliderRef.current) return;
                setSliderWidth(sliderRef.current.scrollWidth + Math.abs(dragX.get()) - sliderRef.current.offsetWidth);
            }, 0);
        } else {
            setSliderWidth(sliderRef.current.scrollWidth + Math.abs(dragX.get()) - sliderRef.current.offsetWidth);
        }
    }, [myCalendarStore.vacationList]);

    const handleModalOpen = () => {
        setDialogOpen(true);
    };

    const handleModalClose = () => {
        setDialogOpen(false);
    };

    const handleExpandedChange = () => {
        setExpanded((pv) => !pv);
    };

    return (
        <div className="flex flex-col gap-2 sticky z-20 top-3 vacation-surface p-2 rounded-md 2xl:rounded-3xl h-fit 2xl:p-7 overflow-hidden">
            <div className="hidden 2xl:flex flex-col items-center">
                <div className="flex items-center gap-2">
                    <span className="main-block-heading">Информация об отпуске</span>
                    <div className="cursor-pointer" onClick={handleModalOpen}>
                        <InfoIcon />
                    </div>
                    <VacationRulesDialog open={dialogOpen} onClose={handleModalClose} />
                </div>
                <VacationImage />
            </div>
            <div>
                <ModeSwitch />
            </div>
            <motion.div
                id="functionalBlock"
                className="flex flex-col gap-2"
                initial={false}
                variants={variants}
                animate={expanded ? "expanded" : "collapsed"}
            >
                <div id="remaining-days-block">
                    {myCalendarStore.vacationMode === "planMode" ? (
                        <div>
                            <div className="flex items-center gap-2">Дней запланированного отпуска</div>
                            {!myCalendarStore.loadingVacations && (
                                <span className="font-bold">{myCalendarStore.remainingPlanDays}</span>
                            )}
                        </div>
                    ) : (
                        <div>
                            <span>Дней отпуска осталось </span>
                            {myCalendarStore.remainingVacationDays !== null && (
                                <span className="font-bold">{myCalendarStore.remainingVacationDays}</span>
                            )}
                        </div>
                    )}
                </div>
                <div id="vacation-list-block" ref={sliderRef}>
                    {myCalendarStore.vacationList.filter((el) => !el.hidden).length > 0 ? (
                        <motion.div
                            className="flex gap-2 2xl:flex-col min-bubble"
                            drag={!desktopMatches ? "x" : false}
                            style={{ x: dragX }}
                            dragConstraints={{ left: -sliderWidth, right: 0 }}
                        >
                            {myCalendarStore.vacationList
                                .filter((el) => !el.hidden)
                                .map((el, idx) => (
                                    <VacationBubble vacation={el} key={idx} />
                                ))}
                        </motion.div>
                    ) : (
                        <div className="min-bubble bg-white dark:dark-surface-300 rounded-md flex justify-center items-center text-lg">
                            Отпуска не найдены
                        </div>
                    )}
                </div>
                <div className="">
                    <SubmitPlanButton />
                </div>
            </motion.div>
            <div className="2xl:hidden flex justify-center relative">
                <motion.button
                    className="rounded py-0.5 px-5 bg-white dark:dark-surface-400"
                    onClick={handleExpandedChange}
                    whileTap={{ scale: 0.9 }}
                >
                    {expanded ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                </motion.button>
            </div>
        </div>
    );
};

export default observer(VacationPlanBlock);
