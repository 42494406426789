import React from "react";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login";
import CreateNews from "../pages/News/CreateNews";
import PrivateRoute from "../components/PrivateRoute";
import MyNews from "../pages/News/MyNews";
import EditNews from "../pages/News/EditNews";
import AllNews from "../pages/News/AllNews";
import ViewNews from "../pages/News/ViewNews";
import MyProfile from "../pages/Users/MyProfile";
import AllUsers from "../pages/Users/AllUsers";
import UserProfile from "../pages/Users/UserProfile";
import CompanyStructure from "../pages/Users/CompanyStructure";
import NotFound from "../pages/errors/404";
import About from "../pages/Users/About";
import Vacation from "../pages/Vacation";
import Calendar from "../pages/Calendar";

interface RouteSubItem {
    path: string;
    element: React.ReactNode;
}

interface RouteItem {
    path: string;
    element: React.ReactNode;
    children?: Array<RouteSubItem>;
}

const routes: Array<RouteItem> = [
    { path: "/login", element: <LoginPage /> },
    { path: "/", element: <PrivateRoute />, children: [{ path: "/", element: <AllNews /> }] },

    // Новости
    { path: "/news/create", element: <PrivateRoute />, children: [{ path: "/news/create", element: <CreateNews /> }] },
    { path: "/news/mine", element: <PrivateRoute />, children: [{ path: "/news/mine", element: <MyNews /> }] },
    { path: "/news/all", element: <PrivateRoute />, children: [{ path: "/news/all", element: <AllNews /> }] },
    {
        path: "/news/edit/:id",
        element: <PrivateRoute />,
        children: [{ path: "/news/edit/:id", element: <EditNews /> }],
    },
    {
        path: "/news/view/:id",
        element: <PrivateRoute />,
        children: [{ path: "/news/view/:id", element: <ViewNews /> }],
    },

    // Пользователи
    { path: "/users/me", element: <PrivateRoute />, children: [{ path: "/users/me", element: <MyProfile /> }] },
    { path: "/users/all", element: <PrivateRoute />, children: [{ path: "/users/all", element: <AllUsers /> }] },
    {
        path: "/users/profile/:id",
        element: <PrivateRoute />,
        children: [{ path: "/users/profile/:id", element: <UserProfile /> }],
    },

    // Компания
    {
        path: "/company/structure",
        element: <PrivateRoute />,
        children: [{ path: "/company/structure", element: <CompanyStructure /> }],
    },
    // {
    //     path: "/company/about",
    //     element: <PrivateRoute />,
    //     children: [{ path: "/company/about", element: <About /> }],
    // },

    // Отпуск
    {
        path: "/vacation",
        element: <PrivateRoute />,
        children: [{ path: "/vacation", element: <Vacation /> }],
    },

    // Другое
    { path: "/calendar", element: <PrivateRoute />, children: [{ path: "/calendar", element: <Calendar /> }] },

    // Ошибки
    { path: "*", element: <NotFound /> },
];

export default routes;
