import { useContext, BaseSyntheticEvent } from "react";
import AuthContext from "../../../../context/AuthContext";
import Button from "../../../../components/inputs/Button";
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    TextField,
    Button as MuiButton,
    Tooltip,
} from "@mui/material";
import { AuthContextType } from "../../../../context/AuthContext";

export interface NewsInterface {
    refusal_reason?: string;
}

interface StatusInterface {
    name: string;
    code_name: string;
}

interface ButtonsBlockInterface {
    status?: StatusInterface;
    loadingButtonName: string;
    dialogOpen: boolean;
    setDialogOpen: Function;
    handleClickOpen: Function;
    refusalReason: string;
    setRefusalReason: Function;
    newsData: NewsInterface;
    buttonClasses?: string;
}

const ButtonsBlock = ({
    status,
    loadingButtonName,
    dialogOpen,
    setDialogOpen,
    handleClickOpen,
    refusalReason,
    setRefusalReason,
    newsData,
    buttonClasses,
}: ButtonsBlockInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    const handleClose = () => {
        setDialogOpen(false);
    };

    if (!domainStore.userIsLoading) {
        switch (status?.code_name) {
            case "draft":
                if (domainStore.isModerator) {
                    return (
                        <div className="flex gap-2">
                            <Button
                                text="Сохранить черновик"
                                color="secondary"
                                name="draft"
                                disabled={!!loadingButtonName}
                                loading={loadingButtonName === "draft"}
                                className={buttonClasses}
                            />
                            <Button
                                text="Опубликовать"
                                name="publish"
                                disabled={!!loadingButtonName}
                                loading={loadingButtonName === "publish"}
                                className={buttonClasses}
                            />
                        </div>
                    );
                }
                return (
                    <div className="flex gap-2">
                        <Button
                            text="Сохранить черновик"
                            color="secondary"
                            name="draft"
                            disabled={!!loadingButtonName}
                            loading={loadingButtonName === "draft"}
                            className={buttonClasses}
                        />
                        <Button
                            text="Отправить на модерацию"
                            name="moderate"
                            disabled={!!loadingButtonName}
                            loading={loadingButtonName === "moderate"}
                            className={buttonClasses}
                        />
                    </div>
                );
            case "on_moderation":
                if (domainStore.isModerator) {
                    return (
                        <div className="flex gap-2">
                            <Button
                                text="Отклонить"
                                name="declineDialog"
                                color="secondary"
                                disabled={!!loadingButtonName}
                                loading={loadingButtonName === "declineDialog"}
                                onClick={handleClickOpen}
                                className={buttonClasses}
                            />
                            <Button
                                text="Опубликовать"
                                name="publish"
                                disabled={!!loadingButtonName}
                                loading={loadingButtonName === "publish"}
                                className={buttonClasses}
                            />
                            <Dialog
                                open={dialogOpen}
                                onClose={handleClose}
                                maxWidth="sm"
                                fullWidth
                                PaperProps={{ component: "form" }}
                            >
                                <DialogTitle>
                                    <div>Отказ в публикации</div>{" "}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText sx={{ mb: 1 }}>Укажите причину</DialogContentText>
                                    <TextField
                                        fullWidth
                                        required
                                        value={refusalReason}
                                        onChange={(e: BaseSyntheticEvent) => setRefusalReason(e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <MuiButton color="secondary" onClick={() => setDialogOpen(false)}>
                                        Отмена
                                    </MuiButton>
                                    <MuiButton
                                        color="signature"
                                        type="submit"
                                        name="decline"
                                        disabled={!!loadingButtonName}
                                    >
                                        Подтвердить
                                    </MuiButton>
                                </DialogActions>
                            </Dialog>
                        </div>
                    );
                }
                return <div className="px-2 py-2 bg-yellow-400 text-white rounded-xl">{status.name}</div>;
            case "declined":
                return (
                    <Tooltip title={newsData.refusal_reason} placement="top" arrow>
                        <div className="px-2 py-2 bg-red-500 text-white rounded-xl">{status.name}</div>
                    </Tooltip>
                );
            case "published":
                if (domainStore.isModerator) {
                    return (
                        <Button
                            text="Сохранить"
                            name="save"
                            disabled={!!loadingButtonName}
                            loading={loadingButtonName === "save"}
                            onClick={handleClickOpen}
                            className={buttonClasses}
                        />
                    );
                }
                return (
                    <Tooltip title={newsData.refusal_reason} placement="top" arrow>
                        <div className="px-2 py-2 bg-green-500 text-white rounded-xl">{status.name}</div>
                    </Tooltip>
                );
        }
    }

    return null;
};

export default ButtonsBlock;
