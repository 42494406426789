import { useContext, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import "./styles.css";
import { YearSelector } from "../YearSelector";
import { observer } from "mobx-react-lite";
import Tools from "./components/Tools";
import SubordinateCard from "./components/SubordinateCard";
import { NoResults } from "../../../ListFeedback";

const SubordinatesList = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [allUsersExpanded, setAllUsersExpanded] = useState<boolean>(true);

    return (
        <div className="sticky z-20 top-3">
            <div className="mb-3 flex justify-center 2xl:hidden">
                <YearSelector />
            </div>
            <div className="base-layer p-1 sm:p-3 2xl:p-5">
                <h1 className="main-block-heading mb-2 2xl:mb-5">Информация об отпусках сотрудников</h1>
                <div className="mb-3">
                    <Tools allUsersExpanded={allUsersExpanded} setAllUsersExpanded={setAllUsersExpanded} />
                </div>
                {!myCalendarStore.loadingSubordinates ? (
                    <div>
                        {!!myCalendarStore.subordinates.filter(myCalendarStore.subordinatesFilter).length ? (
                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1 2xl:max-h-[600px] 2xl:pe-2 overflow-y-auto">
                                {myCalendarStore.subordinates
                                    .filter(myCalendarStore.subordinatesFilter)
                                    .map((el, idx) => (
                                        <SubordinateCard
                                            key={idx}
                                            subordinate={el}
                                            allUsersExpanded={allUsersExpanded}
                                        />
                                    ))}
                            </div>
                        ) : (
                            <div>
                                <NoResults />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col gap-5">
                        {[0, 1, 2, 3].map((el) => (
                            <div key={el} className="loading-color w-full h-20 rounded-xl" />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(SubordinatesList);
