import { createContext } from "react";
import MyCalendarStore from "./store";
import { Dayjs } from "dayjs";
import { SubordinateVacationInterface } from "./store";

export type MyCalendarContextType = {
    myCalendarStore: MyCalendarStore;
};

export interface LocalDateInterface {
    date: Dayjs;
    behavior: ScrollBehavior;
}

export type ManagerViewContextType = {
    localDate: LocalDateInterface;
    setLocalDate: (a: any) => void;
    vacationObjectId: number | null;
    setVacationObjectId: (a: any) => void;
};

export const ManagerViewContext = createContext<ManagerViewContextType | null>(null);
export const MyCalendarContext = createContext<MyCalendarContextType | null>(null);
