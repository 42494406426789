import { useState, useRef } from "react";
import { UpperLevelCommentStore, LowerLevelCommentStore } from "../../store";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import { CommentInterface } from "../../interfaces";
import MyEmojiPicker from "../../../../../components/inputs/MyEmojiPicker";

interface EditableTextInterface {
    store: UpperLevelCommentStore | LowerLevelCommentStore;
    comment: CommentInterface;
}

const EditableText = ({ store, comment }: EditableTextInterface) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [focused, setFocused] = useState(true);
    const variants = {
        simple: {
            borderColor: "#B9B9B9",
        },
        focused: {
            borderColor: "#000000",
        },
    };

    return (
        <div>
            {store.editMode ? (
                <div className="emoji-input">
                    <motion.textarea
                        autoFocus
                        rows={3}
                        className="w-full p-2 outline-none border-solid border-2 rounded-lg dark:dark-surface-400 dark:!border-[#717171]"
                        value={store.editedText}
                        onChange={(e) => {
                            store.setEditedText(e.target.value);
                        }}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        placeholder="Комментарий"
                        variants={variants}
                        animate={focused ? "focused" : "simple"}
                        initial={false}
                        ref={inputRef}
                    />
                    <div className="w-full flex justify-between mt-2">
                        <MyEmojiPicker inputRef={inputRef} textValue={store.editedText} setValue={store.setEditedText} />
                        <div className="flex gap-3">
                            <motion.button
                                className="py-1 px-2 rounded-xl"
                                onClick={() => {
                                    store.setEditMode(false);
                                    store.setEditedText(store.comment.text);
                                }}
                                whileHover={{ backgroundColor: "#B9B9B9", color: "#ffffff" }}
                            >
                                Отмена
                            </motion.button>
                            <motion.button
                                className="py-1 px-2 rounded-xl text-white bg-[#ab2121] dark:dark-primary-300"
                                onClick={() => {
                                    if (store.editedText === comment.text) {
                                        store.setEditMode(false);
                                    } else if (store.editedText.trim()) {
                                        store.editComment();
                                    }
                                }}
                            >
                                Сохранить
                            </motion.button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="whitespace-pre-wrap break-word">{comment.text}</div>
            )}
        </div>
    );
};

export default observer(EditableText);
