import { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NewsService from "../../../services/NewsService";
import { enqueueSnackbar } from "notistack";
import AuthContext from "../../../context/AuthContext";
import useMyTipTapEditor from "../../../hooks/useMyTipTapEditor";
import NewsChangeBase from "../components/NewsChangeBase";
import { NewsItemInterface } from "./interfaces";
import { AuthContextType } from "../../../context/AuthContext";

interface TagsInterface {
    id: number;
    name: string;
    color: string;
}

interface SelectTagsInterface extends TagsInterface {
    selected: boolean;
}

const EditNews = () => {
    const [notFound, setNotFound] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [loading, setLoading] = useState<boolean>(true);
    const [newsData, setNewsData] = useState<NewsItemInterface>({});
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [titleImage, setTitleImage] = useState<any>(null);
    const [serverTags, setServerTags] = useState<Array<SelectTagsInterface>>([]);
    const [images, setImages] = useState<Array<any>>([]);
    const [loadingButtonName, setLoadingButtonName] = useState<string>("");
    const [formDisabled, setFormDisabled] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [refusalReason, setRefusalReason] = useState<string>("");

    const textRef = useRef<HTMLTextAreaElement>(null);

    const [editor, tipTapEditor]: any = useMyTipTapEditor({
        text,
        setText,
        disabled: formDisabled,
        placeholder: "Введите основной текст новости, чтобы его увидели пользователи портала",
    });

    useEffect(() => {
        async function fetchInfo() {
            await NewsService.getTags()
                .then(async (fetchedTags) => {
                    await NewsService.myNewsItemRetrieve(params.id as any)
                        .then((res) => {
                            setNewsData(res.data);
                            setTitle(res.data.title);
                            setText(res.data.text);
                            editor.commands.setContent(res.data.text);
                            setTitleImage(res.data.title_image);

                            setImages(res.data.images);

                            setServerTags(
                                fetchedTags.data.map((el: TagsInterface) => {
                                    return {
                                        ...el,
                                        selected: !!res.data.tags.filter((e: TagsInterface) => e.id === el.id).length,
                                    };
                                })
                            );
                            setLoading(false);
                        })
                        .catch((e) => {
                            if (e.response.status === 404) {
                                setNotFound(true);
                            } else {
                                console.error(e);
                                enqueueSnackbar("Ошибка запроса новости");
                            }
                        });
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar("Ошибка запроса тегов");
                });
        }
        fetchInfo();
    }, []);

    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.height = "auto";
            textRef.current.style.height = textRef.current.scrollHeight + "px";
        }
    }, [text]);

    useEffect(() => {
        setFormDisabled(
            (!domainStore.userIsLoading &&
                !domainStore.isModerator &&
                !loading &&
                newsData.status?.code_name !== "draft") ||
                newsData.status?.code_name === "declined"
        );
    }, [domainStore.userIsLoading, domainStore.isModerator, loading, newsData]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let status;
        const config = { headers: { "Content-type": "multipart/form-data" } };
        let formData = new FormData();

        async function updateNews() {
            try {
                setLoadingButtonName(event.nativeEvent.submitter.name);
                await NewsService.updateNews(Number(params.id), formData, config);
                navigate("/news/mine");
                enqueueSnackbar("Новость успешно обновлена");
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка обновления новости");
            } finally {
                setLoadingButtonName("");
            }
        }

        async function declineNews() {
            setLoadingButtonName(event.nativeEvent.submitter.name);
            try {
                await NewsService.performAction("decline", Number(params.id), refusalReason);
                navigate("/news/mine");
                enqueueSnackbar("Новость успешно обновлена");
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка обновления новости");
            } finally {
                setLoadingButtonName("");
            }
        }

        switch (event.nativeEvent.submitter.name) {
            case "draft":
                status = 1;
                break;
            case "moderate":
                status = 2;
                break;
            case "declineDialog":
                setDialogOpen(true);
                return;
            case "decline":
                status = 3;
                break;
            default:
                status = 4;
        }

        if (status === 3) {
            declineNews();
        } else {
            if (!titleImage) {
                enqueueSnackbar("Необходимо приложить главное фото");
                return;
            }
            if (editor && !editor.state.doc.textContent.trim().length) {
                enqueueSnackbar("Необходимо заполнить текст статьи");
                return;
            }
            const data = {
                title: title,
                text: text,
                tags: serverTags.filter((el) => el.selected),
                status: {
                    id: status,
                },
                images: [] as any,
            };
            Array.from(images).forEach((image) => {
                if (image.name) {
                    formData.append("uploaded_images", image);
                } else {
                    data.images.push(image);
                }
            });
            formData.append("data", JSON.stringify(data));
            formData.append("title_image", titleImage);
            updateNews();
        }
    };

    return (
        <NewsChangeBase
            action="edit"
            pageTitle="Редактирование новости"
            text={text}
            handleSubmit={handleSubmit}
            titleImage={titleImage}
            setTitleImage={setTitleImage}
            title={title}
            setTitle={setTitle}
            serverTags={serverTags}
            setServerTags={setServerTags}
            images={images}
            setImages={setImages}
            loadingButtonName={loadingButtonName}
            editor={editor}
            tipTapEditor={tipTapEditor}
            formDisabled={formDisabled}
            notFound={notFound}
            loading={loading}
            newsData={newsData}
            refusalReason={refusalReason}
            setRefusalReason={setRefusalReason}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
        />
    );
};

export default EditNews;
