import { makeAutoObservable, reaction } from "mobx";
import UserService from "../../services/UserService";
import { enqueueSnackbar } from "notistack";
import { Crop, PixelCrop } from "react-image-crop";
import DomainStore from "../../stores/domainStore";
import { SelectChangeEvent } from "@mui/material";

interface TagsInterface {
    id: number;
    name: string;
    color: string;
}

export interface BirthdateInterface {
    d: number;
    m: number;
    y?: number;
}

export interface PositionInterface {
    id: number;
    name: string;
}

export interface UserInterface {
    first_name: string;
    last_name: string;
    email: string;
    tags: Array<TagsInterface>;
}

export interface AllCompanyInterface {
    id: number;
    name: string;
}

export interface AllDepartmentInterface {
    id: number;
    name: string;
    company: AllCompanyInterface;
}

export interface AllProfilesInterface {
    thumbnail: string;
    department: AllDepartmentInterface;
    position: PositionInterface;
    telegram_username?: string;
}

export interface AllUsersInterface {
    id: number;
    first_name: string;
    last_name: string;
    profile: AllProfilesInterface;
    email?: string;
}

export interface ProfileInterface {
    user_id: number;
    description: string;
    user: UserInterface;
    department: AllDepartmentInterface;
    position: PositionInterface;
    phone: string;
    photo: string;
    thumbnail: string;
    birthdate: BirthdateInterface;
    manager: { id: number; first_name: string; last_name: string };
    telegram_username: string | null;
    extension_number: string;
    company_joined_date: string;
}

interface PrivacyInterface {
    show_birthdate: boolean;
    show_phone: boolean;
    show_tg_username: boolean;
}

export class MyProfileStore {
    name: string = "myProfile";
    profile: ProfileInterface | null = null;
    domainStore: DomainStore;

    newDescription: string = "";
    newPhone: string = "";
    serverTags: Array<TagsInterface> = [];
    selectedTags: Array<number> = [];
    privacy: PrivacyInterface;
    newPrivacy: PrivacyInterface;

    photo: any = null;
    photoLoading: boolean = false;
    photoAsFile: File | null = null;
    crop: Crop | undefined = undefined;
    completedCrop: PixelCrop | undefined = undefined;

    deleteDialogOpen: boolean = false;
    photoDialogOpen: boolean = false;

    loadingEdit: boolean = false;

    tgLinkDisabled: boolean = false;
    notFound: boolean = false;

    colleagues: Array<AllUsersInterface> = [];
    colleaguesLoading: boolean = true;
    colleaguesError: boolean = false;

    constructor(domainStore: DomainStore) {
        this.domainStore = domainStore;
        const privacy = {
            show_birthdate: true,
            show_phone: true,
            show_tg_username: true,
        };
        this.privacy = privacy;
        this.newPrivacy = privacy;
        this.setNewPrivacy(privacy);
        makeAutoObservable(this);
    }

    setProfile = (profile: ProfileInterface) => {
        this.profile = profile;
    };

    setNewDescription = (desc: string) => {
        this.newDescription = desc;
    };

    setNewPhone = (phone: string) => {
        this.newPhone = phone;
    };

    setServerTags = (tags: Array<TagsInterface>) => {
        this.serverTags = tags;
    };

    setSelectedTags = (tags: Array<number>) => {
        this.selectedTags = tags;
    };

    setLoadingEdit = (loading: boolean) => {
        this.loadingEdit = loading;
    };

    setPhoto = (photo: any) => {
        this.photo = photo;
    };

    setPhotoAsFile = (photo: File | null) => {
        this.photoAsFile = photo;
    };

    setPhotoDialogOpen = (open: boolean) => {
        this.photoDialogOpen = open;
    };

    setDeleteDialogOpen = (open: boolean) => {
        this.deleteDialogOpen = open;
    };

    setCrop = (crop: Crop | undefined) => {
        this.crop = crop;
    };

    setCompletedCrop = (completedCrop: PixelCrop | undefined) => {
        this.completedCrop = completedCrop;
    };

    setTgLinkDisabled = (bool: boolean) => {
        this.tgLinkDisabled = bool;
    };

    setPrivacy = (privacy: PrivacyInterface) => {
        this.privacy = privacy;
    };

    setNewPrivacy = (newPrivacy: PrivacyInterface) => {
        this.newPrivacy = newPrivacy;
    };

    setColleagues = (colleagues: Array<AllUsersInterface>) => {
        this.colleagues = colleagues;
    };

    setColleaguesLoading = (bool: boolean) => {
        this.colleaguesLoading = bool;
    };

    setColleaguesError = (bool: boolean) => {
        this.colleaguesError = bool;
    };

    toggleTag = (id: number) => {
        if (this.selectedTags.includes(id)) {
            this.setSelectedTags(this.selectedTags.filter((el) => el !== id));
        } else {
            this.setSelectedTags([...this.selectedTags, id]);
        }
    };

    fetchProfile = async () => {
        interface respInterface {
            id: number;
        }
        const resp = await UserService.getMyProfile();

        setTimeout(() => {
            this.setProfile(resp.data);
            this.setNewDescription(resp.data.description);
            this.setNewPhone(resp.data.phone);
            this.setSelectedTags(resp.data.user.tags.map((el: respInterface) => el.id));
            const privacy = {
                show_birthdate: !resp.data.user.privacy.hide_birthdate,
                show_phone: !resp.data.user.privacy.hide_phone,
                show_tg_username: !resp.data.user.privacy.hide_tg_username,
            };
            this.setPrivacy(privacy);
            this.setNewPrivacy(privacy);
        }, 0);
    };

    fetchUserTags = async () => {
        await UserService.getUserTags()
            .then((resp) => {
                this.setServerTags(resp.data);
            })
            .catch((e) => {
                enqueueSnackbar("Ошибка запроса тегов");
                console.error(e);
            });
    };

    fetchColleagues = async () => {
        setTimeout(async () => {
            await UserService.getMyColleagues()
                .then((resp) => {
                    this.setColleagues(resp.data);
                })
                .catch((e) => {
                    this.setColleaguesError(true);
                    enqueueSnackbar("Ошибка");
                    console.error(e);
                })
                .finally(() => {
                    this.setColleaguesLoading(false);
                });
        }, 0);
    };

    editProfile = async () => {
        this.setLoadingEdit(true);
        setTimeout(async () => {
            await UserService.editMyProfileInfo({
                description: this.newDescription.trim(),
                tags: this.selectedTags,
            })
                .then((resp) => {
                    this.setProfile({
                        ...this.profile,
                        description: resp.data.description,
                        user: {
                            ...this.profile?.user,
                            tags: resp.data.tags,
                        },
                    } as ProfileInterface);
                    this.setNewDescription(resp.data.description);
                    this.setNewPhone(resp.data.phone);
                    enqueueSnackbar("Данные обновлены");
                })
                .catch((e) => {
                    enqueueSnackbar("Ошибка");
                    console.error(e);
                })
                .finally(() => {
                    this.setLoadingEdit(false);
                });
        }, 0);
    };

    editPrivacy = async () => {
        this.setLoadingEdit(true);
        setTimeout(async () => {
            await UserService.editMyProfileInfo({
                hide_birthdate: !this.newPrivacy.show_birthdate,
                hide_phone: !this.newPrivacy.show_phone,
                hide_tg_username: !this.newPrivacy.show_tg_username,
            })
                .then((resp) => {
                    const privacy = {
                        show_birthdate: !resp.data.hide_birthdate,
                        show_phone: !resp.data.hide_phone,
                        show_tg_username: !resp.data.hide_tg_username,
                    };
                    this.setPrivacy(privacy);
                    enqueueSnackbar("Данные обновлены");
                })
                .catch((e) => {
                    enqueueSnackbar("Ошибка");
                    console.error(e);
                })
                .finally(() => {
                    this.setLoadingEdit(false);
                });
        }, 0);
    };

    editProfilePhoto = async (thumbnail: Blob) => {
        this.setLoadingEdit(true);
        setTimeout(async () => {
            const config = { headers: { "Content-type": "multipart/form-data" } };
            let formData = new FormData();

            const photoName = this.photoAsFile && this.photoAsFile.name ? this.photoAsFile.name : "sample.jpeg";

            formData.append("photo", this.photoAsFile as File, photoName);
            formData.append("thumbnail", thumbnail as Blob, photoName);

            await UserService.editMyProfilePhoto(formData, config)
                .then((resp) => {
                    enqueueSnackbar("Фото обновлено");
                    this.setProfile({
                        ...this.profile,
                        thumbnail: resp.data.thumbnail,
                        photo: resp.data.photo,
                    } as ProfileInterface);
                    this.setPhotoDialogOpen(false);
                    this.setCrop(undefined);
                    this.setCompletedCrop(undefined);

                    this.domainStore.loadThumbnail(resp.data.thumbnail);
                    this.domainStore.setUser({
                        ...this.domainStore.user,
                        profile: {
                            ...this.domainStore.user.profile,
                            thumbnail: resp.data.thumbnail,
                        },
                    });
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar("Ошибка");
                })
                .finally(() => {
                    this.setLoadingEdit(false);
                });
        }, 0);
    };

    deleteProfilePhoto = async () => {
        this.setLoadingEdit(true);
        setTimeout(async () => {
            const config = { headers: { "Content-type": "multipart/form-data" } };
            let formData = new FormData();

            formData.append("photo", "");
            formData.append("thumbnail", "");

            await UserService.editMyProfilePhoto(formData, config)
                .then((resp) => {
                    enqueueSnackbar("Фото удалено");
                    this.setProfile({
                        ...this.profile,
                        thumbnail: "",
                        photo: "",
                    } as ProfileInterface);
                    this.setDeleteDialogOpen(false);
                    this.setCrop(undefined);
                    this.setCompletedCrop(undefined);
                    this.setPhoto(null);

                    this.domainStore.setUser({
                        ...this.domainStore.user,
                        profile: {
                            ...this.domainStore.user.profile,
                            thumbnail: "",
                        },
                    });
                })
                .catch((e) => {
                    console.error(e);
                    enqueueSnackbar("Ошибка");
                })
                .finally(() => {
                    this.setLoadingEdit(false);
                });
        }, 0);
    };

    linkTgAccount = async () => {
        this.setTgLinkDisabled(true);
        await UserService.get_tg_confirm_code()
            .then((resp) => {
                window.location.href = `https://t.me/${process.env.REACT_APP_BOT_NAME}?start=${btoa(
                    resp.data.telegram_confirm_code
                )}`;
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка");
                this.setTgLinkDisabled(false);
            });
    };

    cancelTgLink = async () => {
        this.setTgLinkDisabled(true);
        await UserService.editMyProfileInfo({ telegram_id: null, telegram_username: null })
            .then(() => {
                enqueueSnackbar("Аккаунт telegram больше не связан с текущей учётной записью");
                this.setProfile({ ...this.profile, telegram_username: null } as ProfileInterface);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка");
            })
            .finally(() => this.setTgLinkDisabled(false));
    };
}

export class AllUsersStore {
    users: Array<AllUsersInterface> = [];
    companies: Array<AllCompanyInterface> = [];
    departments: Array<AllDepartmentInterface> = [];

    search: string = "";
    company: string = "";
    department: AllDepartmentInterface | null = null;
    page: number = 1;
    pageSize: number = 51;
    hasMore: boolean = false;
    total: number = 0;
    loadingUsers: boolean = true;
    loadingMore: boolean = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.page,
            () => {
                this.fetchUsers();
            }
        );

        reaction(
            () => this.search,
            (val) => {
                if (this.page === 1) {
                    this.fetchUsers();
                } else {
                    this.setPage(1);
                }
            },
            {
                delay: 1000,
            }
        );
    }

    setUsers = (users: Array<AllUsersInterface>) => {
        this.users = users;
    };

    setSearch = (search: string) => {
        this.search = search;
    };

    setPage = (page: number) => {
        this.page = page;
    };

    setUsersLoading = (bool: boolean) => {
        this.loadingUsers = bool;
    };

    setLoadingMore = (bool: boolean) => {
        this.loadingMore = bool;
    };

    setHasMore = (bool: boolean) => {
        this.hasMore = bool;
    };

    setCompanies = (companies: Array<AllCompanyInterface>) => {
        this.companies = companies;
    };

    setDepartments = (departments: Array<AllDepartmentInterface>) => {
        this.departments = departments;
    };

    setCompany = (company: string) => {
        this.company = company;
    };

    setDepartment = (department: AllDepartmentInterface | null) => {
        this.department = department;
    };

    setTotal = (total: number) => {
        this.total = total;
    };

    fetchUsers = async () => {
        if (this.page === 1) {
            this.setUsersLoading(true);
        } else {
            this.setLoadingMore(true);
        }

        setTimeout(async () => {
            try {
                const users = await UserService.getUsers(
                    this.page,
                    this.pageSize,
                    this.search,
                    this.company,
                    this.department ? String(this.department.id) : ""
                );
                if (this.page === 1) {
                    this.setUsers(users.data.results);
                } else {
                    this.setUsers([...this.users, ...users.data.results]);
                }
                this.setHasMore(!!users.data.next);
                this.setTotal(users.data.count);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка");
            } finally {
                this.setUsersLoading(false);
                this.setLoadingMore(false);
            }
        }, 0);
    };

    fetchCompanies = async () => {
        setTimeout(async () => {
            try {
                const companies = await UserService.getCompanies();
                this.setCompanies(companies.data);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка");
            }
        }, 0);
    };

    fetchDepartments = async () => {
        setTimeout(async () => {
            try {
                const departments = await UserService.getDepartments(this.company);
                this.setDepartments(departments.data);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка");
            }
        }, 0);
    };

    handleCompanyChange = async (e: SelectChangeEvent) => {
        if (e.target.value) this.fetchDepartments();
        this.setDepartment(null);
        this.setCompany(e.target.value);

        if (this.page === 1) {
            await this.fetchUsers();
        } else {
            this.setPage(1);
        }
    };

    handleDepartmentChange = async (event: React.SyntheticEvent, newValue: AllDepartmentInterface | null) => {
        this.setDepartment(newValue);

        if (this.page === 1) {
            await this.fetchUsers();
        } else {
            this.setPage(1);
        }
    };
}

export class UserProfileStore {
    name: string = "userProfile";
    profile: ProfileInterface | null = null;
    domainStore: DomainStore;
    notFound: boolean = false;

    photo: any = null;
    photoLoading: boolean = false;

    constructor(domainStore: DomainStore) {
        this.domainStore = domainStore;
        makeAutoObservable(this);
    }

    setNotFound = (bool: boolean) => {
        this.notFound = bool;
    };

    setProfile = (profile: ProfileInterface) => {
        this.profile = profile;
    };

    setPhoto = (photo: any) => {
        this.photo = photo;
    };

    fetchProfile = async (id: number) => {
        await UserService.getUserProfile(id)
            .then((resp) => {
                setTimeout(() => {
                    this.setProfile(resp.data);
                }, 0);
            })
            .catch((e) => {
                if (e.toJSON().status === 404) {
                    this.setNotFound(true);
                } else {
                    enqueueSnackbar("Ошибка");
                    console.error(e);
                }
            });
    };
}
