import { observer } from "mobx-react-lite";
import { useContext } from "react";
import dayjs from "dayjs";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import TodayIcon from "@mui/icons-material/Today";

const Today = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const handleTodayClick = () => {
        myCalendarStore.setDate(dayjs());
        const now = dayjs();
        myCalendarStore.setScrollDate(dayjs({ day: now.date(), month: now.month(), year: now.year() }));
    };

    return (
        <div className="my-tool-icon" title="Сегодня" onClick={handleTodayClick}>
            <TodayIcon />
        </div>
    );
};

export default observer(Today);
