import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import useMeasure from "react-use-measure";
import "./styles.css";
import AuthContext, { AuthContextType } from "../../../../../../context/AuthContext";

const ModeSwitch = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const PADDING_X = 12;
    const TAP_DOWN = 0.9;
    const TAP_UP = 1;
    const [animate, setAnimate] = useState<"create" | "plan">(
        myCalendarStore.vacationMode === "createMode" ? "create" : "plan"
    );
    const [tapping, setTapping] = useState<boolean>(false);
    const [containerRef, containerProps] = useMeasure();
    const [createRef, createProps] = useMeasure();
    const [planRef, planProps] = useMeasure();

    const variants = {
        create: {
            x: 0,
            width: 117,
            scale: tapping ? TAP_DOWN : TAP_UP,
        },
        plan: {
            x: containerProps.width - 200,
            width: 152,
            scale: tapping ? TAP_DOWN : TAP_UP,
        },
    };

    const handleModeSwitch = () => {
        if (myCalendarStore.vacationMode === "createMode") {
            myCalendarStore.setVacationMode("planMode");
        } else {
            myCalendarStore.setVacationMode("createMode");
        }
    };

    const handleOnTapStart = () => {
        setTapping(true);
    };

    const handleOnTapEnd = () => {
        setTapping(false);
    };

    const chooseAnimation = (option: "create" | "plan") => {
        if (
            (option === "create" && myCalendarStore.vacationMode === "createMode") ||
            (option === "plan" && myCalendarStore.vacationMode === "planMode")
        ) {
            if (domainStore.theme === "dark") {
                return {
                    backgroundColor: "#A688FA",
                };
            } else {
                return {
                    backgroundColor: "#93c5fd",
                };
            }
        }
        if (domainStore.theme === "dark") {
            return {
                backgroundColor: "#3F3F3F",
            };
        } else {
            return {
                backgroundColor: "#ffffff",
            };
        }
    };

    useEffect(() => {
        if (myCalendarStore.vacationMode === "createMode") {
            setAnimate("create");
        } else {
            setAnimate("plan");
        }
    }, [myCalendarStore.vacationMode]);

    return (
        <motion.button
            ref={containerRef}
            className="shadow-inner relative w-full h-10 bg-white dark:dark-surface-300 rounded-md flex justify-between items-center cursor-pointer overflow-hidden"
            onClick={handleModeSwitch}
            style={{ padding: `0 ${PADDING_X}px` }}
            onTapStart={handleOnTapStart}
            onTap={handleOnTapEnd}
            onTapCancel={handleOnTapEnd}
        >
            <motion.div ref={createRef} className="mode-div" animate={chooseAnimation("create")} initial={false}>
                Создание
            </motion.div>
            <motion.div ref={planRef} className="mode-div" animate={chooseAnimation("plan")} initial={false}>
                Планирование
            </motion.div>
        </motion.button>
    );
};

export default ModeSwitch;
