import { useContext, useEffect, useState, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { observer } from "mobx-react-lite";
import { SubordinateInterface } from "../../../../store";
import { ManagerViewContext, ManagerViewContextType } from "../../../../context";
import { SubordinateVacationInterface } from "../../../../store";

interface CellDayInterface {
    day: Dayjs;
    subordinate: SubordinateInterface;
}

const CellDay = ({ day, subordinate }: CellDayInterface) => {
    const ref = useRef<HTMLTableCellElement>(null);
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { vacationObjectId, setVacationObjectId } = useContext(ManagerViewContext) as ManagerViewContextType;
    const [usedVacationDays, setUsedVacationDays] = useState<Array<number>>([0]);
    const [currVacation, setCurrVacation] = useState<SubordinateVacationInterface | null>(null);

    useEffect(() => {
        let flag = false;
        for (const vacation of subordinate.vacations) {
            if (dayjs(vacation.date_start).isSame(day)) {
                const usedVacDays = dayjs(vacation.date_end).diff(dayjs(vacation.date_start), "days") + 1;
                let newList: Array<number> = [];
                for (let i: number = 0; i < usedVacDays; i++) {
                    newList.push(i);
                }
                flag = true;
                setCurrVacation(vacation);
                setUsedVacationDays(newList);
            }
        }
        if (!flag) {
            setUsedVacationDays([0]);
        }
    }, [myCalendarStore.subordinates]);

    useEffect(() => {
        if (vacationObjectId && ref.current && currVacation && vacationObjectId === currVacation.id) {
            ref.current.scrollIntoView({ behavior: "smooth", });
            setVacationObjectId(null);
        }
    }, [vacationObjectId]);

    if (subordinate.vacations.filter((el) => day.isBetween(el.date_start, el.date_end, null, "(]")).length) {
        return null;
    }

    return (
        <td
            className={`border border-gray-300 dark:border-gray-400 px-1.5 relative`}
            colSpan={usedVacationDays.length}
            ref={ref}
        >
            <div className={`cell py-2`}>
                {currVacation && (
                    <div className="relative z-10 rounded-full size-full bg-orange-300">
                        <div className="absolute inset-0 flex justify-center items-center font-bold text-xs md:text-sm lg:text-base">
                            {dayjs(currVacation.date_start).format("DD.MM")} -{" "}
                            {dayjs(currVacation.date_end).format("DD.MM")}
                        </div>
                    </div>
                )}
            </div>
            {currVacation && (
                <div className="absolute -inset-y-[1px] -inset-x-[1px] z-0">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                {usedVacationDays.map((el, idx) => (
                                    <td key={idx} className="border border-gray-300 dark:border-gray-400">
                                        <div className="cell"></div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </td>
    );
};

export default observer(CellDay);
