import { observer } from "mobx-react-lite";
import { useState, useContext, MouseEvent, BaseSyntheticEvent } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { Menu, MenuItem } from "@mui/material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

const View = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = {
        year: {
            text: "Год",
        },
        month: {
            text: "Месяц",
        },
    };

    const changeView = (e: BaseSyntheticEvent) => {
        myCalendarStore.setView(e.target.dataset.view);
        handleClose();
    };

    return (
        <div className="my-tool-icon" title="Вид">
            <div onClick={handleClick}>
                <SpaceDashboardIcon />
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {myCalendarStore.viewList.map((view) => (
                    <MenuItem onClick={changeView} key={view} data-view={view}>
                        {options[view].text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default observer(View);
