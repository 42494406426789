import MainLayout from "../../layouts/MainLayout";
import MyCalendar from "../../components/MyCalendar";

const Calendar = () => {
    return (
        <MainLayout>
            <div className="avx-page-h mb-3">Календарь</div>
            <MyCalendar view="month" viewList={["month", "year"]} fetchList={["birthdate", "holidays"]} />
        </MainLayout>
    );
};

export default Calendar;
