import { RefObject, useState, useContext } from "react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
} from "@floating-ui/react";
import { emojiCategories } from "./TipTapEditor/utils";
import EmojiPicker, { EmojiStyle, EmojiClickData } from "emoji-picker-react";
import { observer } from "mobx-react-lite";
import AuthContext from "../../context/AuthContext";
import { Theme } from "emoji-picker-react";
import { AuthContextType } from "../../context/AuthContext";

interface MyEmojiPickerInterface {
    inputRef: RefObject<HTMLTextAreaElement | HTMLInputElement>;
    textValue: string;
    setValue: Function;
}

// элементу-обёртке необходимо добавить класс emoji-input для того, чтобы вставлять эмодзи без потери фокуса
const MyEmojiPicker = ({ inputRef, textValue, setValue }: MyEmojiPickerInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [theme, setTheme] = useState(domainStore.theme === "dark" ? Theme.DARK : Theme.LIGHT);
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(10), flip({ fallbackAxisSideDirection: "end" }), shift()],
        whileElementsMounted: autoUpdate,
        placement: "bottom-start",
    });

    const click = useClick(context);
    const dismiss = useDismiss(context, {
        outsidePress(event: any) {
            if (event.target.matches(".emoji-input *")) return false;
            return true;
        },
    });
    const role = useRole(context);
    const { getFloatingProps } = useInteractions([click, dismiss, role]);

    const handleEmojiMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(!isOpen);
        setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
        }, 10);
    };

    // Такой способ необходим, так как в библиотеке есть баг с кешированием функции
    const handleEmojiClick = (e: React.BaseSyntheticEvent) => {
        if (e.target.matches(".epr-emoji-native") && inputRef.current) {
            const cursor: any = inputRef.current.selectionStart;
            const emoji = String(e.target.innerHTML);

            setValue(textValue.slice(0, cursor) + emoji + textValue.slice(cursor));
            const newCursor = cursor + emoji.length;
            setTimeout(() => {
                if (inputRef.current) inputRef.current.setSelectionRange(newCursor, newCursor);
            }, 10);
            inputRef.current.focus();
        }
    };

    return (
        <div>
            <button
                className="text-gray-600 dark:text-white"
                title="Эмодзи"
                onClick={handleEmojiMenuClick}
                ref={refs.setReference}
            >
                <SentimentSatisfiedAltIcon />
            </button>
            {isOpen && (
                <FloatingFocusManager context={context} modal={false} closeOnFocusOut={false}>
                    <div
                        className="z-20"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                        onClick={handleEmojiClick}
                    >
                        <EmojiPicker
                            theme={theme}
                            skinTonesDisabled={true}
                            searchPlaceHolder="Поиск"
                            previewConfig={{ showPreview: false }}
                            categories={emojiCategories as any}
                            searchDisabled
                            className="!w-[300px] !h-[230px] xl:!w-[400px] xl:!h-[270px]"
                            emojiStyle={EmojiStyle.NATIVE}
                            emojiVersion={"5.0"}
                        />
                    </div>
                </FloatingFocusManager>
            )}
        </div>
    );
};

export default observer(MyEmojiPicker);
