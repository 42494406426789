import { makeAutoObservable } from "mobx";
import MyCalendarStore from "../../../../store";
import Cookies from "universal-cookie";
import { BaseSyntheticEvent } from "react";

export default class EventsFilterClass {
    myCalendarStore: MyCalendarStore;
    birthdateFilterChecked: boolean;
    preHolidayFilterChecked: boolean;
    cookies: Cookies;

    constructor(myCalendarStore: MyCalendarStore) {
        this.myCalendarStore = myCalendarStore;
        this.cookies = new Cookies(null, { path: window.location.pathname });

        this.birthdateFilterChecked =
            this.cookies.get("birthdateFilterChecked") === undefined
                ? true
                : !!this.cookies.get("birthdateFilterChecked");

        this.preHolidayFilterChecked =
            this.cookies.get("preHolidayFilterChecked") === undefined
                ? true
                : !!this.cookies.get("preHolidayFilterChecked");
        makeAutoObservable(this);
    }

    setBirthdateFilterChecked = (bool: boolean) => {
        this.birthdateFilterChecked = bool;
    };

    setPreHolidayFilterChecked = (bool: boolean) => {
        this.preHolidayFilterChecked = bool;
    };

    handleBirthdateFilterChange = (e: BaseSyntheticEvent) => {
        this.setBirthdateFilterChecked(e.target.checked);
        this.cookies.set("birthdateFilterChecked", e.target.checked);
        this.myCalendarStore.setEvents([
            ...this.myCalendarStore.events.map((el) => {
                if (el.type === "birthdate") el.hidden = !e.target.checked;
                return el;
            }),
        ]);
    };

    handlePreHolidayFilterChange = (e: BaseSyntheticEvent) => {
        this.setPreHolidayFilterChecked(e.target.checked);
        this.cookies.set("preHolidayFilterChecked", e.target.checked);
        this.myCalendarStore.setEvents([
            ...this.myCalendarStore.events.map((el) => {
                if (el.extra_type === "Предпраздничный") el.hidden = !e.target.checked;
                return el;
            }),
        ]);
    };
}
