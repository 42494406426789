import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useInView } from "react-intersection-observer";
import { useEffect, useContext, useState, useCallback, useRef } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { weekDayDict } from "../../../../common/constants";
import { ManagerViewContext, ManagerViewContextType } from "../../../../context";

interface MonthHeaderRowInterface {
    monthNum: number;
    monthData: Array<Dayjs>;
}

const MonthHeaderRow = ({ monthNum, monthData }: MonthHeaderRowInterface) => {
    const ref = useRef<any>(null);
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { localDate } = useContext(ManagerViewContext) as ManagerViewContextType;
    const [threshold, setThreshold] = useState<number>(0.2);
    const xsMatches = useMediaQuery("(max-width:549px)");
    const smMatches = useMediaQuery("(min-width:550px)");
    const lgMatches = useMediaQuery("(min-width:1100px)");
    const {
        ref: inViewRef,
        inView,
        entry,
    } = useInView({
        threshold: threshold,
    });

    const setRefs = useCallback(
        (node: any) => {
            // Ref's from useRef needs to have the node assigned to `current`
            ref.current = node;
            // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
            inViewRef(node);
        },
        [inViewRef]
    );

    useEffect(() => {
        if (inView && myCalendarStore.calendarRendered) {
            myCalendarStore.setDate(dayjs({ y: myCalendarStore.date.year(), month: monthNum }));
        }
    }, [inView]);

    useEffect(() => {
        if (ref.current && !myCalendarStore.calendarRendered && monthNum === myCalendarStore.date.month()) {
            ref.current.scrollIntoView({ behavior: "auto", block: "nearest" });
            myCalendarStore.setCalendarRendered(true);
        }
    }, [ref.current]);

    useEffect(() => {
        if (lgMatches) {
            setThreshold(0.35);
        } else if (smMatches) {
            setThreshold(0.3);
        } else if (xsMatches) {
            setThreshold(0.2);
        }
    }, [xsMatches, smMatches, lgMatches]);

    useEffect(() => {
        if (entry && localDate.date.month() === monthNum) {
            entry.target.scrollIntoView({ behavior: localDate.behavior, block: "nearest" });
        }
    }, [localDate]);

    return (
        <th ref={setRefs} colSpan={monthData.length} className="row-padding">
            <motion.table className="w-full" animate={{ opacity: monthNum === myCalendarStore.date.month() ? 1 : 0.6 }}>
                <tbody>
                    <tr>
                        {monthData.map((el, idx) => (
                            <td key={idx} className="min-w-8 md:min-w-12 xl:min-w-16">
                                <div className="week-day">{weekDayDict[el.day()]}</div>
                            </td>
                        ))}
                    </tr>
                    <tr className="">
                        {monthData.map((el, idx) => (
                            <td
                                key={idx}
                                className="min-w-8 first:rounded-l-2xl last:rounded-r-2xl bg-blue-200 dark:dark-primary-500"
                            >
                                <div className="week-day">{el.date()}</div>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </motion.table>
        </th>
    );
};

export default observer(MonthHeaderRow);
