import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import "./styles.css";
import EventsFilter from "./components/EventsFilter";
import Today from "./components/Today";
import View from "./components/View";

const Tools = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    return (
        <div className="flex items-center gap-4 vacation-surface w-fit py-1 px-2 rounded-md">
            <span className="font-semibold">Инструменты:</span>
            <div className="flex gap-3 items-center">
                {myCalendarStore.viewList.length > 1 && <View />}
                {myCalendarStore.showToday && <Today />}
                {myCalendarStore.eventsFilter && <EventsFilter />}
            </div>
        </div>
    );
};

export default observer(Tools);
