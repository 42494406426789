import { Dialog, DialogTitle, DialogContent } from "@mui/material";

interface VacationRulesDialogInterface {
    open: boolean;
    onClose: Function;
}

const VacationRulesDialog = ({ open, onClose }: VacationRulesDialogInterface) => {
    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Правила планирования отпуска</DialogTitle>
            <DialogContent>
                <div className="text-sm 2xl:text-base">
                    <p className="mb-1">
                        Если на период отпуска приходится один и более праздничных дней, то их соответствующе количество
                        не учитывается при расчёте оставшихся дней отпуска. При этом рядом с числом календарных дней
                        отпуска появится число без учёта праздников.{" "}
                    </p>
                    <p className="mb-1">
                        При планировании отпуска на следующий год необходимо соблюсти следующие условия:
                    </p>
                    <ul className="list-disc list-inside">
                        <li>
                            Продолжительность одной из частей ежегодного отпуска должна составлять не менее 14
                            календарных дней
                        </li>
                        <li>
                            Для отправки на рассмотрение руководителю необходимо израсходовать все доступные дни
                            запланированного отпуска
                        </li>
                    </ul>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default VacationRulesDialog;
