import {useEffect, useContext} from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { observer } from "mobx-react-lite";
import LoadingScreen from "./LoadingScreen";
import { AuthContextType } from "../context/AuthContext";

const PrivateRoute = () => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    useEffect(() => {
        if (localStorage.getItem("authTokens")) {
            domainStore.checkAuth();
        }
    }, []);

    if ((domainStore.isLoading || domainStore.userIsLoading) && domainStore.isAuth) {
        return <LoadingScreen />;
    } else if (domainStore.isAuth) {
        return <Outlet />;
    } else {
        let searchString: string;
        // Первый кейс нужен только из-за strict mode в реакт (страница рендерится 2 раза в дев моде)
        if (window.location.pathname.startsWith("/login")) {
            searchString = window.location.search;
        } else {
            searchString = `?next=${window.location.pathname}`;
        }
        return <Navigate to={`/login${searchString}`} />;
    }
};

export default observer(PrivateRoute);
